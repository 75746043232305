import { useContext, useEffect, useState } from "react";
import { Routes, useNavigate, } from "react-router-dom";
import { UserContext } from "./context/userContext";
import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
// Auth
import Auth from "./pages/Auth/Auth";
import AuthStaff from "./pages/Auth/AuthAdmin";

import RegisterPasien from "./pages/RegisterPasien/Register";
import RegisterPasienOnline from "./pages/RegisterPasien/RegisterOnline";
import LoginPasienOnline from "./pages/RegisterPasien/LoginOnline";
import DashboardPasienOnline from "./pages/RegisterPasien/HomePagePasienOnline";
import DashboardPasienOnlinePenampung from "./pages/RegisterPasien/HomePagePasienOnlinepenampung";
import DashboardPasienOnlinePenampungTambah from "./pages/RegisterPasien/HomePagePasienOnlinepenampungTambah";
import DashboardPasienOnlineProfile from "./pages/RegisterPasien/HomePagePasienOnlineProfile";
import DashboardPasienOnlinePassword from "./pages/RegisterPasien/HomePagePasienOnlinePassword";
import DashboardPasienOnlineDoctor from "./pages/RegisterPasien/HomePagePasienOnlineDoctor";
import AppoinmentPasien from "./pages/AppoinmentPasien/AppoinmentPasien";
import AppoinmentPasienOnline from "./pages/AppoinmentPasien/AppoinmentPasienOnline";

// Admin Pasien Medis Record
import AdminHome from "./pages/Admin/AdminHome";
import AdminPasien from "./pages/AdminPasien/AdminPasien";
import AdminPasienPhysicalCheck from "./pages/AdminPasien/AdminPasienPhysicalCheck";
import AdminPasienMedicalAttention from "./pages/AdminPasien/AdminPasienMedicalAttention";
import AdminPasienMedicalRecord from "./pages/AdminPasien/AdminPasienMedicalRecord";
import AdminPasienOdontogram from "./pages/AdminPasien/AdminPasienOdontogram";
import AdminPasienSoap from "./pages/AdminPasien/AdminPasienSoap";

import AdminPasienSetup from "./pages/AdminPasien/AdminPasienSetup";
import AdminPasienOdontogramSetup from "./pages/AdminPasien/AdminPasienPrintoutOdontogram";

// Admin Pasien 
import AdminPasienAdd from "./pages/AdminPasien/AdminPasienAdd";
import AdminPasienUpdate from "./pages/AdminPasien/AdminPasienUpdate";
import AdminPasienAddTreatment from "./pages/Admin/AdminPasienTreatmentAdd";
import AdminPasienUpdateTreatment from "./pages/Admin/AdminPasienTreatmentUpdate";

// Admin Item
import AdminItem from "./pages/AdminItem/AdminItem";

// Admin Pasien
import Appointment from "./pages/AdminAppointment/Appointment";
import AppointmentAdd from "./pages/AdminAppointment/AppointmentAdd";
import AppointmentUpdate from "./pages/AdminAppointment/AppointmentUpdate";
import AppointmentTreatmentPlanAdd from "./pages/AdminAppointment/AppointmentTreatmentAdd";
import AppointmentPasienSetup from "./pages/AdminAppointment/AppointmentPasienSetup";

// Admin Pasien
import Treatment from "./pages/AdminTreatment/Treatment";
// import TreatmentAdd from "./pages/AdminTreatment/TreatmentAdd";
// import TreatmentUpdate from "./pages/AdminTreatment/TreatmentUpdate";

// Admin Pasien
import TreatmentPrices from "./pages/AdminTreatmentPrices/TreatmentPrices";
// import TreatmentPricesAdd from "./pages/AdminTreatmentPrices/TreatmentPricesAdd";
// import TreatmentPricesUpdate from "./pages/AdminTreatmentPrices/TreatmentPricesUpdate";

// Admin Pasien
import TreatmentCategory from "./pages/AdminTreatmentCategory/TreatmentCategory";
import TreatmentCategoryAdd from "./pages/AdminTreatmentCategory/TreatmentCategoryAdd";
import TreatmentCategoryUpdate from "./pages/AdminTreatmentCategory/TreatmentCategoryUpdate";

// Admin Agreement
import Agreement from "./pages/AdminAgreement/Agreement";
import AgreementAdd from "./pages/AdminAgreement/AgreementAdd";
import AgreementUpdate from "./pages/AdminAgreement/AgreementUpdate";

// Admin Room
import Room from "./pages/AdminRoom/Room";
import RoomAdd from "./pages/AdminRoom/RoomAdd";
import RoomUpdate from "./pages/AdminRoom/RoomUpdate";

// Admin Room
import Floor from "./pages/AdminFloor/Floor";
import FloorAdd from "./pages/AdminFloor/FloorAdd";
import FloorUpdate from "./pages/AdminFloor/FloorUpdate";

// Admin Payment
import Payment from "./pages/AdminPayment/Payment";
import PaymentAdd from "./pages/AdminPayment/PaymentAdd";
import PaymentUpdate from "./pages/AdminPayment/PaymentUpdate";

// Admin Payment
import PaymentType from "./pages/AdminPaymentType/PaymentType";
import PaymentTypeAdd from "./pages/AdminPaymentType/PaymentTypeAdd";
import PaymentTypeUpdate from "./pages/AdminPaymentType/PaymentTypeUpdate";

// Admin Payment
import Fees from "./pages/AdminFees/Fees";
import FeesAdd  from "./pages/AdminFees/FeesAdd";
import FeesUpdate from "./pages/AdminFees/FeesUpdate";

// Admin Payment
import Schedules from "./pages/AdminSchedules/Schedules";
import SchedulesAdd  from "./pages/AdminSchedules/SchedulesAdd";
import SchedulesUpdate from "./pages/AdminSchedules/SchedulesUpdate";

// Admin Consent
import Consent from "./pages/AdminConsent/Consent";
import ConsentAdd from "./pages/AdminConsent/ConsentAdd";
import ConsentUpdate from "./pages/AdminConsent/ConsentUpdate";

// Admin Role
import AdminRole from "./pages/AdminRole/AdminRole";
import AdminRoleAdd from "./pages/AdminRole/AdminRoleAdd";
import AdminRoleUpdate from "./pages/AdminRole/AdminRoleUpdate";

// Admin Role Permission
import AdminRolePermision from "./pages/AdminRolePermission/AdminRolePermission";

// Admin User Privilage
import AdminUserPrivilage from "./pages/AdminUserPrivilage/AdminUserPrivilage";
import AdminUserAddPrivilage from "./pages/AdminUserPrivilage/AdminUserAddPrivilage";
import AdminUserUpdatePrivilage from "./pages/AdminUserPrivilage/AdminUserUpdatePrivilage";

// Admin User
import AdminUser from "./pages/AdminUser/AdminUser";
import AdminUserAdd from "./pages/AdminUser/AdminUserAdd";
import AdminUserUpdate from "./pages/AdminUser/AdminUserUpdate";

// Admin Doctor Spesialis
import AdminDoctorSpecialist from "./pages/AdminUserSpecialist/AdminUserSpecialist";

// Admin Specialist
import AdminSpecialist from "./pages/AdminSpecialist/AdminSpecialist";

// Admin Specialist
import AdminPersentaseAssurance from "./pages/AdminPersentaseAssurance/AdminPersentaseAssurance";

// Admin User Permisiion
import AdminUserPermission from "./pages/AdminUserPermission/AdminUserPermission";

// Admin Room
import AdminMenus from "./pages/AdminMenus/Menus";
import AdminMenusAdd from "./pages/AdminMenus/MenusAdd";
import AdminMenusUpdate from "./pages/AdminMenus/MenusUpdate";

// Admin Role
import Invoices from "./pages/AdminInvoice/Invoices";
import InvoicesSetup from "./pages/AdminInvoice/InvoicesSetup";
import InvoicesAssuranceSetup from "./pages/AdminInvoiceAssurance/InvoiceAssuranceSetup";
import InvoicesAdd from "./pages/AdminInvoice/InvoicesAdd";
import InvoicesUpdate from "./pages/AdminInvoice/InvoicesUpdate";

// Admin Role
import MedicalInvoices from "./pages/AdminInvoiceMedical/InvoicesMedical";
import MedicalInvoicesSetup from "./pages/AdminInvoiceMedical/InvoicesMedicalSetup";
import MedicalInvoicesAdd from "./pages/AdminInvoiceMedical/InvoicesMedicalAdd";
import MedicalInvoicesUpdate from "./pages/AdminInvoiceMedical/InvoicesMedicalUpdate";

// Treatment Plan
import TreatmentPlan from "./pages/AdminTreatmentPlan/TreatmentPlan";
import TreatmentPlanAdd from "./pages/AdminTreatmentPlan/TreatmentPlanAdd";
import TreatmentPlanUpdate from "./pages/AdminTreatmentPlan/TreatmentPlanUpdate";

// Admin Modules
import AdminModules from "./pages/AdminModules/AdminModules";
import AdminModulesAdd from "./pages/AdminModules/AdminModulesAdd";
import AdminModulesUpdate from "./pages/AdminModules/AdminModulesUpdate";

// Admin Sub Modules
import AdminSUbModules from "./pages/AdminModulesSub/AdminModulesSub";

// Admin Sub Modules
import AdminDiagnosaPatient from "./pages/AdminDiagnosaPatient/AdminDiagnosaPatient";

// Admin Tooth Surface
import ToothSurfaces from "./pages/AdminOdontogramToothSurfaces/AdminOdontogramToothSurfaces";
import ToothSurfacesAdd  from "./pages/AdminOdontogramToothSurfaces/AdminOdontogramToothSurfacesAdd";
import ToothSurfacesUpdate from "./pages/AdminOdontogramToothSurfaces/AdminOdontogramToothSurfacesUpdate";

// Admin Tooth Condition
import ToothCondition from "./pages/AdminOdontogramToothCondition/AdminOdontogramToothCondition";
import ToothConditionAdd  from "./pages/AdminOdontogramToothCondition/AdminOdontogramToothConditionAdd";
import ToothConditionUpdate from "./pages/AdminOdontogramToothCondition/AdminOdontogramToothConditionUpdate";

// Admin Protesa
import Protesa from "./pages/AdminOdontogramProtesa/AdminOdontogramProtesa";
import ProtesaAdd  from "./pages/AdminOdontogramProtesa/AdminOdontogramProtesaAdd";
import ProtesaUpdate from "./pages/AdminOdontogramProtesa/AdminOdontogramProtesaUpdate";

// Admin Restorative
import Restorative from "./pages/AdminOdontogramRestorative/AdminOdontogramRestorative";
import RestorativeAdd  from "./pages/AdminOdontogramRestorative/AdminOdontogramRestorativeAdd";
import RestorativeUpdate from "./pages/AdminOdontogramRestorative/AdminOdontogramRestorativeUpdate";

// Admin Restorative Material
import RestorativeMaterial from "./pages/AdminOdontogramRestorativeMaterial/AdminOdontogramRestorativeMaterial";
import RestorativeMaterialAdd  from "./pages/AdminOdontogramRestorativeMaterial/AdminOdontogramRestorativeMaterialAdd";
import RestorativeMaterialUpdate from "./pages/AdminOdontogramRestorativeMaterial/AdminOdontogramRestorativeMaterialUpdate";

// Admin Finance User Fee
import FinanceUserFee from "./pages/AdminFinanceUserFee/FinanceUserFee";
import FinanceUserFeeAdd from "./pages/AdminFinanceUserFee/FinanceUserFeeAdd";
import FinanceUserFeeUpdate from "./pages/AdminFinanceUserFee/FinanceUserFeeUpdate";

// Admin Finance Rekap User Fee
import FinanceRekapUserFee from "./pages/AdminFinanceRekapUserFee/FinanceRekapUserFee";
import FinanceRekapUserFeeAdd from "./pages/AdminFinanceRekapUserFee/FinanceRekapUserFeeAdd";
import FinanceRekapUserFeeUpdate from "./pages/AdminFinanceRekapUserFee/FinanceRekapUserFeeUpdate";

// Admin Protesa
import MediicalItem from "./pages/AdminMedicalItem/AdminMedicalItem";
import MediicalItemAdd  from "./pages/AdminMedicalItem/AdminMedicalItemAdd";
import MediicalItemUpdate from "./pages/AdminMedicalItem/AdminMedicalItemUpdate";

// Admin Medical Item Type
import MediicalItemType from "./pages/AdminMedicalItemType/AdminMedicalItemType";

// Admin Inventory Item
import InventoryItem from "./pages/AdminInventoryItem/AdminInventoryItem";
import InventoryItemAdd  from "./pages/AdminInventoryItem/AdminInventoryItemAdd";
import InventoryItemUpdate from "./pages/AdminInventoryItem/AdminInventoryItemUpdate";

// Admin Inventory Unit
import InventoryUnit from "./pages/AdminInventoryUnit/AdminInventoryUnit";
import InventoryUnitAdd  from "./pages/AdminInventoryUnit/AdminInventoryUnitAdd";
import InventoryUnitUpdate from "./pages/AdminInventoryUnit/AdminInventoryUnitUpdate";

// Admin Inventory Vendor
import InventoryVendor from "./pages/AdminInventoryVendor/AdminInventoryVendor";
import InventoryVendorAdd  from "./pages/AdminInventoryVendor/AdminInventoryVendorAdd";
import InventoryVendorUpdate from "./pages/AdminInventoryVendor/AdminInventoryVendorUpdate";

// Admin Inventory 
import Inventory from "./pages/AdminInventory/AdminInventory";
import InventoryAdd  from "./pages/AdminInventory/AdminInventoryAdd";
import InventoryUpdate from "./pages/AdminInventory/AdminInventoryUpdate";

// Admin Inventory In
import InventoryIn from "./pages/AdminInventoryIn/AdminInventoryIn";
import InventoryInAdd  from "./pages/AdminInventoryIn/AdminInventoryInAdd";
import InventoryInUpdate from "./pages/AdminInventoryIn/AdminInventoryInUpdate";

// Admin Inventory In
import InventoryOut from "./pages/AdminInventoryOut/AdminInventoryOut";
import InventoryOutAdd  from "./pages/AdminInventoryOut/AdminInventoryOutAdd";
import InventoryOutUpdate from "./pages/AdminInventoryOut/AdminInventoryOutUpdate";

// Admin Inventory In
import PurchaseRequest from "./pages/AdminPurchaseRequest/AdminPurchaseRequest";
import PurchaseRequestAdd  from "./pages/AdminPurchaseRequest/AdminPurchaseRequestAdd";
import PurchaseRequestUpdate from "./pages/AdminPurchaseRequest/AdminPurchaseRequestUpdate";

// Admin Inventory In
import PurchaseOrder from "./pages/AdminPurchaseOrder/AdminPurchaseOrder";
import PurchaseOrderAdd  from "./pages/AdminPurchaseOrder/AdminPurchaseOrderAdd";
import PurchaseOrderUpdate from "./pages/AdminPurchaseOrder/AdminPurchaseOrderUpdate";

// Admin Inventory In
import PurchaseInvoice from "./pages/AdminPurchaseInvoice/AdminPurchaseInvoice";
import PurchaseInvoiceAdd  from "./pages/AdminPurchaseInvoice/AdminPurchaseInvoiceAdd";
import PurchaseInvoiceUpdate from "./pages/AdminPurchaseInvoice/AdminPurchaseInvoiceUpdate";

// Not Found Page
import My404Component from "./pages/NotFoundPage/PageNotFound";

import { Link } from 'react-router-dom';

// // Admin Role
// import AdminRole from "./pages/AdminRole/AdminRole";

// Config API Gateway
import { API, setAuthToken } from "./config/api";

// init token on axios every time the app is refreshed
// if (localStorage.token) {
//   setAuthToken(localStorage.token);
// }

function App() {
  const [state, dispatch] = useContext(UserContext);
  const token = localStorage.getItem("token")

  // useEffect(() => {
  //   if (localStorage.getItem("token") == null || undefined) {
  //       navigate("/");
  //     }
  // }, []);

  // let navigate = useNavigate();
  // useEffect(() => {
  //   if (localStorage.getItem("token") == null || undefined) {
  //       navigate("/");
  //     }
  // }, []);

  // useEffect(() => {
  //   if (localStorage.token) {
  //     setAuthToken(localStorage.token);
  //   }
  //   // Redirect Auth
  //     if (localStorage.token) {
  //       navigate("/home");
  //     }else{
  //       navigate("/")
  //     }
  // }, [state]);

//   const checkUser = async () => {
//     try {
//       const response = await API.get("/check-auth");
//       console.log(response);

//       // If the token incorrect
//       if (response.status === 404) {
//         return dispatch({
//           type: "AUTH_ERROR",
//         });
//       }
//       // Get user data
//       let payload = response.data.data;
//       // Get token from local storage
//       payload.token = localStorage.token;

//       // Send data to useContext
//       dispatch({
//         type: "USER_SUCCESS",
//         payload,
//       });
//     } catch (error) {
//       console.log(error);
//     }
//   };

// useEffect(() => {
//   const token = localStorage.getItem("token")
//   console.log(token+"disini yahh")

//   if (token == null) {
//     console.log("itu betul")
//     navigate("/")
//   }
// },[]);

return (

  <Routes>
      {/* <div className="mx-20 flex gap-5 my-24">
        {
          data_menu.map((user) => {
            return (
              <Link to={`users/${user}`}>User {user}</Link>
            )
          })
        }
      </div> */}
    
      <Route exact path="/" element={<Auth />} />
      <Route exact path="/backyard" element={<AuthStaff />}/>
      
        {/* Register Online Pasien */}
        <Route exact path="/register-pasien" element={<RegisterPasien />} />
        <Route exact path="/register-pasien-online" element={<RegisterPasienOnline />} />
        <Route exact path="/login-pasien-online" element={<LoginPasienOnline />} />
    
        {/* Pasien Online */}
        <Route exact path="/dashboard-pasien-online" element={<DashboardPasienOnline />} />
        <Route exact path="/dashboard-pasien-online-penampung" element={<DashboardPasienOnlinePenampung />} />
        <Route exact path="/dashboard-pasien-online-tambah" element={<DashboardPasienOnlinePenampungTambah/>} />
    
        <Route exact path="/dashboard-pasien-online-profile" element={<DashboardPasienOnlineProfile/>} />
        <Route exact path="/dashboard-pasien-online-ubah-password" element={<DashboardPasienOnlinePassword />} />
        <Route exact path="/dashboard-pasien-list-doctor" element={<DashboardPasienOnlineDoctor/>} />

        <Route exact path="/appoinment-pasien/:code/:id" element={<AppoinmentPasien />} />
        <Route exact path="/appoinment-pasien-online" element={<AppoinmentPasienOnline/>}/>
        
        {/* Dashboard */}
        <Route exact path="/dashboard" element={<AdminHome />}/>

        {/* Admin Pasien Medis  */}
        <Route exact path="/patients" element={<AdminPasien />} />
        <Route exact path="/phy-checks/:id" element={<AdminPasienPhysicalCheck />} />
        <Route exact path="/med-attentions/:id" element={<AdminPasienMedicalAttention />} />
        <Route exact path="/medical-record/:id/:code" element={<AdminPasienMedicalRecord />} />
        <Route exact path="/medical-record/:id" element={<AdminPasienSetup />} />
        <Route exact path="/medical-record-odontogram/:id" element={<AdminPasienOdontogramSetup/>} />
    

        {/* Admin Pasien */}
        <Route exact path="/pasien-admin-add" element={<AdminPasienAdd/>}/>
        <Route exact path="/pasien-admin-update" element={<AdminPasienUpdate />} />
        <Route exact path="/pasien-admin-add-treatment" element={<AdminPasienAddTreatment />} />
        <Route exact path="/pasien-admin-update-treatment" element={<AdminPasienUpdateTreatment />} />

        {/* Admin Item */}
        <Route exact path="/item-admin" element={<AdminItem />} />
        
        {/* Admin Appointment  */}
        <Route exact path="/appointments" element={<Appointment/>}/>
        <Route exact path="/appointments-admin-add" element={<AppointmentAdd/>}/>
        <Route exact path="/appointments-admin-update" element={<AppointmentUpdate />} />
        <Route exact path="/treatment-plans/:id/:id_patient_status" element={<AppointmentTreatmentPlanAdd />} />
        <Route exact path="/medical-record-all/:id/:id_patient" element={<AppointmentPasienSetup/>} />

        {/* Admin Treatment  */}
        <Route exact path="/treatments" element={<Treatment/>}/>
        {/* <Route exact path="/treatments-admin-add" element={<TreatmentAdd/>}/>
        <Route exact path="/treatments-admin-update" element={<TreatmentUpdate />} /> */}
        
        {/* Admin Treatment Prices */}
        <Route exact path="/treatment-prices" element={<TreatmentPrices/>}/>
        {/* <Route exact path="/treatment-prices-add" element={<TreatmentPricesAdd/>}/>
        <Route exact path="/treatment-prices-update" element={<TreatmentPricesUpdate />} /> */}
    
        {/* Admin Treatment Category */}
        <Route exact path="/treatment-categories" element={<TreatmentCategory/>}/>
        <Route exact path="/treatment-categories-add" element={<TreatmentCategoryAdd/>}/>
        <Route exact path="/treatment-categories-update" element={<TreatmentCategoryUpdate />} />

        {/* Admin Agreement  */}
        <Route exact path="/agremeents" element={<Agreement/>}/>
        {/* <Route exact path="/agremeents-admin-add" element={<AgreementAdd/>}/>
        <Route exact path="/agremeents-admin-update" element={<AgreementUpdate />} /> */}

        {/* Admin Consent  */}
        <Route exact path="/consents" element={<Consent/>}/>
        {/* <Route exact path="/consents-admin-add" element={<ConsentAdd/>}/>
        <Route exact path="/consents-admin-update" element={<ConsentUpdate />} /> */}
        
        {/* Admin Roles  */}
        <Route exact path="/privileges/roles" element={<AdminRole/>}/>
        <Route exact path="/privileges/roles-admin-add" element={<AdminRoleAdd/>}/>
        <Route exact path="/privileges/roles-admin-update" element={<AdminRoleUpdate />} />
        
        {/* Admin Role Permision */}
        <Route exact path="/privileges/roles/:id/:name" element={<AdminRolePermision/>}/>

        {/* Admin User  */}
        <Route exact path="/users" element={<AdminUser />} />
        <Route exact path="/users-add" element={<AdminUserAdd />} />
        <Route exact path="/users-update/:id" element={<AdminUserUpdate />} />

        {/* Admin Doctor Specialist  */}
        <Route exact path="/users/specialists" element={<AdminDoctorSpecialist />} />

        {/* Admin Specialist  */}
        <Route exact path="/specialists" element={<AdminSpecialist />} />

        {/* Admin Specialist  */}
        <Route exact path="/persentase-assurances" element={<AdminPersentaseAssurance />} />

        {/* Admin Diagnosis Patient  */}
        <Route exact path="/diagnosa-patients" element={<AdminDiagnosaPatient />} />

        {/* Admin User Privilage */}
        <Route exact path="/privileges/users" element={<AdminUserPrivilage />} />
        <Route exact path="/privileges/users-add" element={<AdminUserAddPrivilage />} />
        <Route exact path="/privileges/users-update/:id" element={<AdminUserUpdatePrivilage />} />

        {/* Admin User Permission */}
        <Route exact path="/permissions/:id/:firstname/:lastname" element={<AdminUserPermission/>}/>

        {/* Admin Menus  */}
        <Route exact path="/privileges/menus" element={<AdminMenus />} />
        <Route exact path="/privileges/menus-add" element={<AdminMenusAdd />} />
        <Route exact path="/privileges/menus-update/:id" element={<AdminMenusUpdate />} />

        {/* Admin Room  */}
        <Route exact path="/rooms" element={<Room/>}/>
        {/* <Route exact path="/rooms-admin-add" element={<RoomAdd/>}/>
        <Route exact path="/rooms-admin-update" element={<RoomUpdate />} /> */}

        {/* Admin Treatment Plan  */}
        <Route exact path="/treatment-plans" element={<TreatmentPlan/>}/>
        <Route exact path="/treatment-planss/:id" element={<TreatmentPlanAdd/>}/>
        <Route exact path="/treatment-plan-update" element={<TreatmentPlanUpdate />} />
        {/* <Route exact path="/treatment-plans/:id" element={<TreatmentPlanDetail/>}/> */}
        
        {/* Admin Floor  */}
        <Route exact path="/floors" element={<Floor />} />
        {/* <Route exact path="/floors-add" element={<FloorAdd />} />
        <Route exact path="/floors-update/:id" element={<FloorUpdate />} /> */}

        {/* Admin Payment  */}
        <Route exact path="/payments" element={<Payment />} />
        {/* <Route exact path="/payments-add" element={<PaymentAdd />} />
        <Route exact path="/payments-update/:id" element={<PaymentUpdate />} /> */}
      
        {/* Admin Payment Type  */}
        <Route exact path="/payment-types" element={<PaymentType />} />
        {/* <Route exact path="/payment-types-add" element={<PaymentTypeAdd />} />
        <Route exact path="/payment-types-update/:id" element={<PaymentTypeUpdate />} /> */}
      
        {/* Admin Fee  */}
        <Route exact path="/fees" element={<Fees />} />
        {/* <Route exact path="/fees-add" element={<FeesAdd />} />
        <Route exact path="/fees-update/:id" element={<FeesUpdate />} /> */}
      
        {/* Admin Fee  */}
        <Route exact path="/schedules" element={<Schedules />} />
        {/* <Route exact path="/schedules-add" element={<SchedulesAdd />} />
        <Route exact path="/schedules-update/:id" element={<SchedulesUpdate />} /> */}

        {/* Admin Invoice  */}
        <Route exact path="/sales-invoices" element={<Invoices />} />
        <Route exact path="/sales-invoices/:id/:invoice_id/:payment_id" element={<InvoicesSetup />} />
        <Route exact path="/sales-invoices-assurances/:id/:invoice_id/:payment_id" element={<InvoicesAssuranceSetup />} />
        <Route exact path="/sales-invoices-add" element={<InvoicesAdd />} />
        <Route exact path="/sales-invoices-update/:id" element={<InvoicesUpdate />} />

        {/* Admin Invoice Medical  */}
        <Route exact path="/medical-invoices" element={<MedicalInvoices />} />
        <Route exact path="/medical-invoices/:id" element={<MedicalInvoicesSetup />} />
        <Route exact path="/medical-invoices-add" element={<MedicalInvoicesAdd />} />
        <Route exact path="/medical-invoices-update/:id" element={<MedicalInvoicesUpdate />} />

        {/* Admin Modules  */}
        <Route exact path="/privileges/modules" element={<AdminModules/>}/>
        <Route exact path="/privileges/modules-add" element={<AdminModulesAdd/>}/>
        <Route exact path="/privileges/modules-update" element={<AdminModulesUpdate />} />
    
        {/* Admin Tooth Surface  */}
        <Route exact path="/tooth-surfaces" element={<ToothSurfaces />} />
        {/* <Route exact path="/tooth-surfaces/add" element={<ToothSurfacesAdd />} />
        <Route exact path="/tooth-surfaces/:id" element={<ToothSurfacesUpdate />} /> */}
        
        {/* Admin Tooth Condition  */}
        <Route exact path="/tooth-conditions" element={<ToothCondition />} />
        {/* <Route exact path="/tooth-conditions/add" element={<ToothConditionAdd />} />
        <Route exact path="/tooth-conditions/:id" element={<ToothConditionUpdate />} /> */}
    
        {/* Admin Protesa  */}
        <Route exact path="/protesas" element={<Protesa />} />
        {/* <Route exact path="/protesas/add" element={<ProtesaAdd />} />
        <Route exact path="/protesas/:id" element={<ProtesaUpdate />} /> */}
      
        {/* Admin Restorative  */}
        <Route exact path="/restoratives" element={<Restorative />} />
        {/* <Route exact path="/restoratives/add" element={<RestorativeAdd />} />
        <Route exact path="/restoratives/:id" element={<RestorativeUpdate />} /> */}
    
        {/* Admin Restorative Material */}
        <Route exact path="/restorative-materials" element={<RestorativeMaterial />} />
        <Route exact path="/restorative-materials/add" element={<RestorativeMaterialAdd />} />
        <Route exact path="/restorative-materials/:id" element={<RestorativeMaterialUpdate />} />
      
        {/* Admin Sub Modules */}
        <Route exact path="/privileges/sub-modules/:id/:slug_name" element={<AdminSUbModules />} />
    
        {/* Admin Odontogram */}
        <Route exact path="/odontograms/:id/:first_name/:last_name" element={<AdminPasienOdontogram />} />

        {/* Admin Soap Patient  */}
        <Route exact path="/soap-patients/:id/:first_name/:last_name" element={<AdminPasienSoap />} />
    
        {/* Admin User Fee  */}
        <Route exact path="/user-fee" element={<FinanceUserFee />} />
        <Route exact path="/user-fee-add" element={<FinanceUserFeeAdd />} />
        <Route exact path="/user-fee-update/:id" element={<FinanceUserFeeUpdate />} />
    
        {/* Admin Floor  */}
        <Route exact path="/rekap-user-fee" element={<FinanceRekapUserFee />} />
        <Route exact path="/rekap-user-fee-add" element={<FinanceRekapUserFeeAdd />} />
        <Route exact path="/rekap-user-fee-update/:id" element={<FinanceRekapUserFeeUpdate />} />
        
        {/* Admin Protesa  */}
        <Route exact path="/medical-items" element={<MediicalItem />} />
        <Route exact path="/medical-items/add" element={<MediicalItemAdd />} />
        <Route exact path="/medical-items/:id" element={<MediicalItemUpdate />} />
    
        {/* Admin Medical Type  */}
        <Route exact path="/medical-item-types" element={<MediicalItemType />} />
        
        {/* Admin Inventory Items  */}
        <Route exact path="/inventory-items" element={<InventoryItem />} />
        {/* <Route exact path="/inventory-items/add" element={<InventoryItemAdd />} />
        <Route exact path="/inventory-items/:id" element={<InventoryItemUpdate />} /> */}
    
        {/* Admin Inventory Units  */}
        <Route exact path="/inventory-units" element={<InventoryUnit />} />
        {/* <Route exact path="/inventory-units/add" element={<InventoryUnitAdd />} />
        <Route exact path="/inventory-units/:id" element={<InventoryUnitUpdate />} /> */}
    
        {/* Admin Inventory Units  */}
        <Route exact path="/inventory-vendors" element={<InventoryVendor />} />
        {/* <Route exact path="/inventory-vendors/add" element={<InventoryVendorAdd />} />
        <Route exact path="/inventory-vendors/:id" element={<InventoryVendorUpdate />} /> */}
        
        {/* Admin Inventory  */}
        <Route exact path="/inventorys" element={<Inventory />} />
        {/* <Route exact path="/inventorys/add" element={<InventoryAdd />} />
        <Route exact path="/inventorys/:id" element={<InventoryUpdate />} /> */}
      
        {/* Admin Inventory In */}
        <Route exact path="/inventory-ins" element={<InventoryIn />} />
        {/* <Route exact path="/inventory-ins/add" element={<InventoryInAdd />} />
        <Route exact path="/inventory-ins/:id" element={<InventoryInUpdate />} /> */}
        
        {/* Admin Inventory  Out */}
        <Route exact path="/inventory-outs" element={<InventoryOut />} />
        {/* <Route exact path="/inventory-outs/add" element={<InventoryOutAdd />} />
        <Route exact path="/inventory-outs/:id" element={<InventoryOutUpdate />} /> */}
    
        {/* Admin Inventory  Out */}
        <Route exact path="/purchase-requests" element={<PurchaseRequest />} />
        <Route exact path="/purchase-requests/add" element={<PurchaseRequestAdd />} />
        <Route exact path="/purchase-requests/:id" element={<PurchaseRequestUpdate />} />
        
        {/* Admin Inventory  Out */}
        <Route exact path="/purchase-orders" element={<PurchaseOrder />} />
        <Route exact path="/purchase-orders/add" element={<PurchaseOrderAdd />} />
        <Route exact path="/purchase-orders/:id" element={<PurchaseOrderUpdate />} />
      
        {/* Admin Inventory  Out */}
        <Route exact path="/purchase-invoices" element={<PurchaseInvoice />} />
        <Route exact path="/purchase-invoices/add" element={<PurchaseInvoiceAdd />} />
        <Route exact path="/purchase-invoices/:id" element={<PurchaseInvoiceUpdate />} />

        {/* Not Found Page */}
        <Route path="*" element={<My404Component />} />
      </Routes>
  );
}

export default App;
