import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaClosedCaptioning, FaTimes, FaBold, FaItalic, FaStrikethrough, FaMicrophone, FaTelegram, FaTelegramPlane} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import LoaderHome from "../../Loader/LoaderHome"
import swal from "sweetalert";
import React, { useRef } from 'react';
import logo_kemenkes from "../../../assets/signature/logo_kemeskes.png"
import axios from "axios";

export default function ModalRoleAdd(props) {
  const token = localStorage.getItem("token");
  // Untuk Close Proops Data
  const [loading, setLoading] = useState(false);
  const [getDataPatients, setGetDataPatients] = useState([]);
  const [getDataDoctorByID, setGetDataDoctorByID] = useState([]);
  const [getDataPhysicalCheck, setGetDataPhysicalCheck] = useState([]);
  const [dataMedicalRecord, setGetDataMedicalRecord] = useState("");
  const [dataRoomSatuSehat, setGetDataRoomSatuSehat] = useState("");
  
  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const [form, setForm] = useState({
    id_appointment: "",
    id_patient: "",
    id_doctor: "",
    patient_name: "",
    token_satu_sehat: "",
    date_appointment: "",
    time_appointment: "",
    arrangement_data: ""
  });

  const GetDataResponseRoomFromSatuSehat = async () => {
    try {
      const fetchParamss = {
        headers: {
          'Authorization': `Bearer ${form?.token_satu_sehat.access_token}`,
          'Content-Type': 'application/json'
        }
      };
      setLoading(true)
      // Insert data for login process
      const response = await axios.get(`/fhir-r4/v1/Location?identifier=http://sys-ids.kemkes.go.id/location/3fc5e997-b653-4b47-928f-4d2e8cf3933b|${form?.arrangement_data.id_room}`,
      fetchParamss);
      // Checking process
      if (response?.data.entry.length !== 0) {
        setGetDataRoomSatuSehat(response.data.entry[0].resource)
        setLoading(false)
      }
    }catch(error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    } 
  }
  
  const GetResponseDataPatients = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/transaction-service/patients/${form?.id_patient}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataPatients(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataMedicalRecord = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/emr-service/medical-invoices/${form?.id_appointment}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataMedicalRecord(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataDoctorByID = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/user-service/privileges/users/${form?.id_doctor}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataDoctorByID(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataPhisicalChecks = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/emr-service/phy-checks/patient/${form?.id_appointment}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataPhysicalCheck(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  useEffect(() => {
    setForm({
      ...form, 
      id_appointment: props?.idAppointmentModalSatuSehat === null? "" : props?.idAppointmentModalSatuSehat,
      id_patient: props?.idPatientModalSatuSehat === null? "" : props?.idPatientModalSatuSehat,
      id_doctor: props?.idDoctorModalSatuSehat === null? "" : props?.idDoctorModalSatuSehat,
      patient_name: props?.patientNameModalSatuSehat === null? "" : props?.patientNameModalSatuSehat,
      token_satu_sehat: props?.getDataSatuSehat === null? "" : props?.getDataSatuSehat,
      date_appointment: props?.dateSatuSehat === null? "" : props?.dateSatuSehat,
      time_appointment: props?.timeSatuSehat === null? "" : props?.timeSatuSehat,
      arrangement_data: props?.arrangementSatuSehat === null? "" : props?.arrangementSatuSehat
    });
  }, [props])

  useEffect(() => {
    if(form?.id_patient !== "" && form?.id_doctor !== "" && form?.id_appointment !== "" && form?.date_appointment !== "" && form?.time_appointment !== "" && form?.arrangement_data !== ""){
      GetResponseDataPatients()
      GetResponseDataDoctorByID()
      GetResponseDataPhisicalChecks()
      GetResponseDataMedicalRecord()
      GetDataResponseRoomFromSatuSehat()
    }
  }, [form?.id_patient, form?.id_doctor, form?.id_appointment])

  // useEffect(() => {
  //   if(dataMedicalRecord?.medical_treatment_invoices?.some(invoice => {
  //     const treatmentData = invoice?.medical_record?.treatment_plan_detail?.treatment_data;
  //     return treatmentData?.code_icd9 !== null || treatmentData?.name_icd9 !== null;
  //   })){
  //       console.log("ini ada data icd nya");
  //   }else {
  //     console.log("tidak ada data icd nya")
  //   }
  // }, [dataMedicalRecord]);

  const PostDataPatient = async () => {
    try {
      const fetchParamss = {
        headers: {
          'Authorization': `Bearer ${form?.token_satu_sehat.access_token}`,
          'Content-Type': 'application/json'
        }
      };
      // Insert data for login process
      const response = await axios.post('/fhir-r4/v1/Patient', 
      {
        resourceType: "Patient",
        meta: {
          profile: [
            "https://fhir.kemkes.go.id/r4/StructureDefinition/Patient"
          ]
        },
        identifier: [
          {
            use: "official",
            system: "https://fhir.kemkes.go.id/id/nik",
            value: getDataPatients?.nic_number
          }
        ],
        active: true,
        name: [
          {
            use: "official",
            text: `${getDataPatients?.first_name} ${getDataPatients?.last_name}`
          }
        ],
        telecom: [
          {
            system: "phone",
            value: getDataPatients?.phone_number,
            use: "mobile"
          }
        ],
        gender: getDataPatients?.gender,
        birthDate: getDataPatients?.date_birth,
        deceasedBoolean: false,
        address: [
          {
            use: "home",
            line: [getDataPatients?.residence_address],
            country: "ID"
          } 
        ],
        multipleBirthInteger: 0
      },
      fetchParamss);
      // Checking process
      if (response?.data.success === true) {
        PostDataPatientEcounter(response.data.data.patient_id);
      }
    }catch(error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      PostDataPatientEcounter(error.response.data.data.resourceId);
    } 
  }
  
  const PostDataPatientEcounter = async (patient_id) => {
    try {
      const fetchParamss = {
        headers: {
          'Authorization': `Bearer ${form?.token_satu_sehat.access_token}`,
          'Content-Type': 'application/json'
        }
      };
      // Insert data for login process
      const response = await axios.post('/fhir-r4/v1/Encounter', 
      {
        resourceType: "Encounter",
        identifier: [
            {
                system: "http://sys-ids.kemkes.go.id/encounter/3fc5e997-b653-4b47-928f-4d2e8cf3933b",
                value: form?.id_appointment
            }
        ],
        status: "arrived",
        class: {
            system: "http://terminology.hl7.org/CodeSystem/v3-ActCode",
            code: "AMB",
            display: "ambulatory"
        },
        subject: {
          reference: `Patient/${patient_id}`,
          display: `${getDataPatients?.first_name} ${getDataPatients?.last_name}`
        },
        participant: [
            {
                type: [
                    {
                        coding: [
                            {
                                system: "http://terminology.hl7.org/CodeSystem/v3-ParticipationType",
                                code: "ATND",
                                display: "attender"
                            }
                        ]
                    }
                ],
                individual: {
                  // "reference": "Practitioner/10018452434",
                  // "display": "Dokter Gigi Budiman"
                  reference: `Practitioner/${getDataDoctorByID?.user_detail.ihs_number}`,
                  display: `${getDataDoctorByID?.user_detail.firstname} ${getDataDoctorByID?.user_detail.lastname}`,
                }
            }
        ],
        period: {
            start: `${form?.date_appointment}T${form?.time_appointment}+00:00`
        },
        location: [
            {
                location: {
                    reference: `Location/${dataRoomSatuSehat.id}`,
                    display: `${dataRoomSatuSehat.name}`
                }
            }
        ],
        statusHistory: [
            {
                status: "arrived",
                period: {
                    start:  `${form?.date_appointment}T${form?.time_appointment}+00:00`
                }
            }
        ],
        serviceProvider: {
            reference: "Organization/3fc5e997-b653-4b47-928f-4d2e8cf3933b"
        }
    },
      fetchParamss);
      // Checking process
      if (response?.data.success === true) {
        PostDataPatientSistole(patient_id, response.data.id);
        PostDataPatientDistole(patient_id, response.data.id);
        PostDataPatientSuhu(patient_id, response.data.id);
        PostDataDiagnosis(patient_id, response.data.id);
        if(dataMedicalRecord?.medical_treatment_invoices?.some(invoice => {
          const treatmentData = invoice?.medical_record?.treatment_plan_detail?.treatment_data;
          return treatmentData?.code_icd9 !== null || treatmentData?.name_icd9 !== null;
        })){
          PostDataTindakan(patient_id, response.data.id)
        }         
      }
    }catch(error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    } 
  }

  const PostDataPatientSistole = async (patient_id, id) => {
    try {
      const fetchParamss = {
        headers: {
          'Authorization': `Bearer ${form?.token_satu_sehat.access_token}`,
          'Content-Type': 'application/json'
        }
      };
      // Insert data for login process
      const response = await axios.post('/fhir-r4/v1/Observation', 
      {
        resourceType: "Observation",
        status: "final",
        category: [
            {
              coding: [
                  {
                      system: "http://terminology.hl7.org/CodeSystem/observation-category",
                      code: "vital-signs",
                      display: "Vital Signs"
                  }
              ]
            }
        ],
        code: {
            coding: [
                {
                  system: "http://loinc.org",
                  code: "8480-6",
                  display: "Systolic blood pressure"
                }
            ]
        },
        subject: {
            reference: `Patient/${patient_id}`,
            display: `${getDataPatients?.first_name} ${getDataPatients?.last_name}`
        },
        performer: [
            {
              reference: `Practitioner/${getDataDoctorByID?.user_detail.ihs_number}`,
              display: `${getDataDoctorByID?.user_detail.firstname} ${getDataDoctorByID?.user_detail.lastname}`
            }
        ],
        encounter: {
            reference: `Encounter/${id}`
        },
        effectiveDateTime: `${getDataPhysicalCheck?.created_at.split(" ")[0]}T${getDataPhysicalCheck?.created_at.split(" ")[1]}+00:00`,
        issued: `${getDataPhysicalCheck?.created_at.split(" ")[0]}T${getDataPhysicalCheck?.created_at.split(" ")[1]}+00:00`,
        bodySite: {
            coding: [
                {
                    system: "http://snomed.info/sct",
                    code: "368209003",
                    display: "Right arm"
                }
            ]
        },
        valueQuantity: {
            value: parseInt(getDataPhysicalCheck?.blood_pressure.split("/")[0]),
            unit: "mm[Hg]",
            system: "http://unitsofmeasure.org",
            code: "mm[Hg]"
        }
    },
      fetchParamss);
      // Checking process
      if (response?.data.success === true) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })
        // GetResponseData()
        setLoading(false)
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false)
    } 
  }

  const PostDataPatientDistole = async (patient_id, id) => {
    try {
      const fetchParamss = {
        headers: {
          'Authorization': `Bearer ${form?.token_satu_sehat.access_token}`,
          'Content-Type': 'application/json'
        }
      };
      console.log(patient_id)
      // Insert data for login process
      const response = await axios.post('/fhir-r4/v1/Observation', 
      {
        resourceType: "Observation",
        status: "final",
        category: [
            {
              coding: [
                  {
                      system: "http://terminology.hl7.org/CodeSystem/observation-category",
                      code: "vital-signs",
                      display: "Vital Signs"
                  }
              ]
            }
        ],
        code: {
            coding: [
                {
                  system: "http://loinc.org",
                  code: "8462-4",
                  display: "Diastolic blood pressure"
                }
            ]
        },
        subject: {
            reference: `Patient/${patient_id}`,
            display: `${getDataPatients?.first_name} ${getDataPatients?.last_name}`
        },
        performer: [
            {
              reference: `Practitioner/${getDataDoctorByID?.user_detail.ihs_number}`,
              display: `${getDataDoctorByID?.user_detail.firstname} ${getDataDoctorByID?.user_detail.lastname}`
            }
        ],
        encounter: {
            reference: `Encounter/${id}`
        },
        effectiveDateTime: `${getDataPhysicalCheck?.created_at.split(" ")[0]}T${getDataPhysicalCheck?.created_at.split(" ")[1]}+00:00`,
        issued: `${getDataPhysicalCheck?.created_at.split(" ")[0]}T${getDataPhysicalCheck?.created_at.split(" ")[1]}+00:00`,
        bodySite: {
            coding: [
                {
                    system: "http://snomed.info/sct",
                    code: "368209003",
                    display: "Right arm"
                }
            ]
        },
        valueQuantity: {
            value: parseInt(getDataPhysicalCheck?.blood_pressure.split("/")[1]),
            unit: "mm[Hg]",
            system: "http://unitsofmeasure.org",
            code: "mm[Hg]"
        }
    },
      fetchParamss);
      // Checking process
      if (response?.data.success === true) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })
        // GetResponseData()
        setLoading(false)
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false)
    } 
  }

  const PostDataPatientSuhu = async (patient_id, id) => {
    try {
      const fetchParamss = {
        headers: {
          'Authorization': `Bearer ${form?.token_satu_sehat.access_token}`,
          'Content-Type': 'application/json'
        }
      };
      console.log(patient_id)
      // Insert data for login process
      const response = await axios.post('/fhir-r4/v1/Observation', 
      {
        resourceType: "Observation",
        status: "final",
        code: {
            coding: [
                {
                  system: "http://loinc.org",
                  code: "8310-5",
                  display: "Body temperature"
                }
            ]
        },
        subject: {
            reference: `Patient/${patient_id}`,
            display: `${getDataPatients?.first_name} ${getDataPatients?.last_name}`
        },
        performer: [
            {
              reference: `Practitioner/${getDataDoctorByID?.user_detail.ihs_number}`,
              display: `${getDataDoctorByID?.user_detail.firstname} ${getDataDoctorByID?.user_detail.lastname}`
            }
        ],
        encounter: {
            reference: `Encounter/${id}`
        },
        valueQuantity: {
            value: parseInt(getDataPhysicalCheck?.body_temperature),
            unit: "C",
            system: "http://unitsofmeasure.org",
            code: "Cel"
        }
    },
      fetchParamss);
      // Checking process
      if (response?.data.success === true) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })
        // GetResponseData()
        setLoading(false)
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false)
    } 
  }

  const PostDataDiagnosis = async (patient_id, id) => {
    try {
      const fetchParamss = {
        headers: {
          'Authorization': `Bearer ${form?.token_satu_sehat.access_token}`,
          'Content-Type': 'application/json'
        }
      };
      // Insert data for login process
      const response = await axios.post('/fhir-r4/v1/Condition', 
      {
        resourceType: "Condition",
        clinicalStatus: {
          coding: [
              {
                  system: "http://terminology.hl7.org/CodeSystem/condition-clinical",
                  code: "active",
                  display: "Active"
              }
          ]
      },
      category: [
          {
              coding: [
                  {
                      system: "http://terminology.hl7.org/CodeSystem/condition-category",
                      code: "encounter-diagnosis",
                      display: "Encounter Diagnosis"
                  }
              ]
          }
      ],
      code: {
          coding: [
              {
                  system: "http://hl7.org/fhir/sid/icd-10",
                  code: dataMedicalRecord?.medical_treatment_invoices[0].medical_record.code_diagnose,
                  display: dataMedicalRecord?.medical_treatment_invoices[0].medical_record.diagnose
              }
          ]
      },
      subject: {
        reference: `Patient/${patient_id}`,
        display: `${getDataPatients?.first_name} ${getDataPatients?.last_name}`
      },
      encounter: {
          reference: `Encounter/${id}`
      },
      onsetDateTime: `${getDataPhysicalCheck?.created_at.split(" ")[0]}T${getDataPhysicalCheck?.created_at.split(" ")[1]}+00:00`,
      recordedDate: `${getDataPhysicalCheck?.created_at.split(" ")[0]}T${getDataPhysicalCheck?.created_at.split(" ")[1]}+00:00`
    },
      fetchParamss);
      // Checking process
      if (response?.data.success === true) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })
        // GetResponseData()
        setLoading(false)
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false)
    } 
  }

  const PostDataTindakan = async (patient_id, id) => {
    try {
      const fetchParamss = {
        headers: {
          'Authorization': `Bearer ${form?.token_satu_sehat.access_token}`,
          'Content-Type': 'application/json',
        },
      };
  
      const validCoding = dataMedicalRecord?.medical_treatment_invoices
        ?.flatMap(invoice => {
          const treatmentData = invoice?.medical_record?.treatment_plan_detail?.treatment_data;
          return treatmentData?.code_icd9 && treatmentData?.name_icd9 ? [{
            system: "http://hl7.org/fhir/sid/icd-9-cm",
            code: treatmentData.code_icd9,
            display: treatmentData.name,
          }] : [];
        });
  
      const code = validCoding.length > 0 ? { coding: validCoding } : undefined;
  
      const payload = {
        resourceType: "Procedure",
        status: "completed",
        category: {
          coding: [
            {
              system: "http://snomed.info/sct",
              code: "277132007",
              display: "Therapeutic procedure",
            },
          ],
          text: "Therapeutic procedure",
        },
        code, 
        subject: {
          reference: `Patient/${patient_id}`,
          display: `${getDataPatients?.first_name} ${getDataPatients?.last_name}`,
        },
        encounter: {
          reference: `Encounter/${id}`,
        },
        performer: [
          {
            actor: {
              reference: `Practitioner/${getDataDoctorByID?.user_detail.ihs_number}`,
              display: `${getDataDoctorByID?.user_detail.firstname} ${getDataDoctorByID?.user_detail.lastname}`,
            },
          },
        ],
      };
  
      // Send the request
      const response = await axios.post('/fhir-r4/v1/Procedure', payload, fetchParamss);
  
      // Handle the response
      if (response?.data.success === true) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false,
        });
        setLoading(false);
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false,
      });
      setLoading(false);
    }
  };
  
  const handleSubmit = async () => {
    try {
      await PostDataPatient();
    } catch (error) {
      swal({
        title: 'Failed',
        text: 'Terjadi kesalahan saat mengirim data.',
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  };
  
  return (
    <Modal {...props}  aria-labelledby="contained-modal-title-center" centered style={{fontFamily:"sans-serif",border:"none"}}>
      {loading && <LoaderHome />}
      <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: "#29B8FF" }}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"white", fontWeight:"600"}}>
          Satu Sehat || {form?.patient_name}
        </div> 
        <div style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",justifyContent:"center", color:"white"}}>
          <FaTimes onClick={props.onHide} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{backgroundColor:"",borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px", border:"none" }}>
      <Form className="mt-" >
        <Col xl='12' sm='12'>

        <div style={{display:"flex", justifyContent:"center",  padding:"0px 0px"}}>
          <img src={logo_kemenkes} style={{minWidth:"320px",maxWidth:"320px", minHeight:"250px", maxHeight:"250px", }}/>
        </div>

        <div className="" style={{display:"flex",justifyContent:"center"}}>
          <div>
            <Button className="mt-2" onClick={handleSubmit} color='primary' block style={{ padding: "8px 15px", fontSize: "12px", borderRadius: "5px"}}>
              <FaTelegramPlane/> Kirim Data
            </Button>
          </div>
        </div>

        </Col>
      </Form>
      </Modal.Body>
    </Modal>
    );
}
  