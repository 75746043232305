import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { useMutation } from "react-query";
import { API } from "../../config/api";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip,CardBody} from 'reactstrap'
import Logo_Dashboard from '../../assets/signature/signature logo.png'
import source from '../../assets/signature/logo-dental-login.jpg'
import { useForm, Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import InputPasswordToggle from './InputToggle'
import swal from "sweetalert";
import { useMediaQuery } from 'react-responsive'
import ShowMoreText from 'react-show-more-text';
import Card from 'react-bootstrap/Card';
import {FaUser, FaBirthdayCake, FaMailBulk, FaTransgender, FaMap, FaAddressCard,FaSave, FaAddressBook, FaUserCircle, FaLevelUpAlt, FaMobile, FaChevronLeft, FaBoxTissue, FaFileMedical, FaPumpMedical, FaClinicMedical, FaBriefcaseMedical, FaHandHoldingMedical} from 'react-icons/fa'
import { useParams } from "react-router";
import Caro1 from '../../assets/signature/logo-login-dental.png'
import Caro2 from '../../assets/signature/background-appoinment.jpg'
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import { FadeLoader, HashLoader } from 'react-spinners';
import { Transition } from "react-transition-group";
import Dropdown from "./DropdownRegister";
import LoaderHome from "../Loader/LoaderHome"

function MyVerticallyCenteredModal(props) {
  const navigate = useNavigate()
  const registerBaru = () => {      
    navigate("/register-pasien");
  };

  const [backgroundImage, setBackgroundImage] = useState("linear-gradient( #00EFFF, #0044FF)");

  const styles = {
    cursor: "pointer",
    flex: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "150px",
    fontSize: "25px",
    backgroundImage: backgroundImage,
    color: "white",
    marginRight: "5px", borderRadius: "5px",
    transition:"all 5"
  };

  const styles2 = {
    cursor: "pointer",
    flex: "100%",
    display: "flex", justifyContent: "center", alignItems: "center", height: "150px", fontSize: "25px", backgroundImage: "linear-gradient( #61FF7B, #00FF2B)", color: "white", marginRight: "5px", borderRadius: "5px"
  }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{backgroundColor:"#DCFFFC"}}>
          <Modal.Title id="contained-modal-title-vcenter">
            Pilih Status Pasien
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{backgroundColor:"#DCFFFC", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px"}}>
          <div style={{display:"flex"}}>
            <div onClick={registerBaru} onMouseEnter={() => setBackgroundImage("linear-gradient( #0044FF, #00EFFF)")} onMouseLeave={() => setBackgroundImage("linear-gradient( #00EFFF, #0044FF)")}  style={styles} >
              <FaHandHoldingMedical style={{fontSize:"50px",marginRight:"10px", color:"white"}}/>Pasien Baru
            </div>
            <div  style={styles2}>
              <FaHandHoldingMedical style={{fontSize:"50px",marginRight:"10px", color:"white"}}/>Pasien Lama
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    );
}
  
function MyVerticallyCenteredModal2(props) {
  const navigate = useNavigate()
  const registerBaru = () => {      
    navigate("/register-pasien");
  };

  const [backgroundImage, setBackgroundImage] = useState("linear-gradient( #00EFFF, #0044FF)");

  const styles = {
    cursor: "pointer",
    flex: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "150px",
    fontSize: "15px",
    backgroundImage: backgroundImage,
    color: "white",
    marginRight: "5px", borderRadius: "5px",
    transition:"all 5"
  };

  const styles2 = {
    cursor: "pointer",
    flex: "100%",
    display: "flex", justifyContent: "center", alignItems: "center", height: "150px", fontSize: "15px", backgroundImage: "linear-gradient( #61FF7B, #00FF2B)", color: "white", marginRight: "5px", borderRadius: "5px"
  }

    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{backgroundColor:"#DCFFFC"}}>
          <Modal.Title id="contained-modal-title-vcenter">
            Pilih Status Pasien
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{backgroundColor:"#DCFFFC", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px"}}>
          <div style={{display:"flex"}}>
            <div onClick={registerBaru} onMouseEnter={() => setBackgroundImage("linear-gradient( #0044FF, #00EFFF)")} onMouseLeave={() => setBackgroundImage("linear-gradient( #00EFFF, #0044FF)")}  style={styles} >
              <FaHandHoldingMedical style={{ fontSize: "50px", marginRight: "10px", color: "white" }} />Pasien Baru
            </div>
            <div  style={styles2}>
              <FaHandHoldingMedical style={{fontSize:"50px",marginRight:"10px", color:"white"}}/>Pasien Lama
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
    );
  }

export default function Login() {
  let navigate = useNavigate();
  document.title = "SDC Apps";
  const [state, dispatch] = useContext(UserContext);
  const [message, setMessage] = useState(null);
  
  const sweetAlert = () => {
    swal({
      title: 'Login Success',
      text: ' ',
      icon: 'success',
      timer: 3000,
      buttons: false
    });
  }

  const registerBaru = () => {      
    navigate("/register-pasien");
  };

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const { email, password } = form;

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = useMutation(async () => {
    try {
      setLoading(true)
     // Configuration
     const config = {
      headers: {
        "Content-type": "application/json",
      },
    };

    const response = await API.post(`transaction-service/patient-logins/login`, 
    {
      email: email? email : "",
      password: password? password : "",
    }
    ,config)
    
    if (response?.status === 200) {
      localStorage.setItem('token', response.data.data.token);
      localStorage.setItem('user_data', JSON.stringify(response.data.data.user)? JSON.stringify(response.data.data.user) : "" )
    setLoading(false)
    swal({
      title: 'Success',
      text: response.data.message,
      icon: 'success',
      timer: 3000,
      buttons: false
    });
      navigate("/dashboard-pasien-online");
    }
      
    } catch (error) {
      setLoading(false)
    swal({
      title: 'Failed',
      text: `${error.response.data.message}`,
      icon: 'error',
      buttons: false
    });
  }
  });

  const [loading, setLoading] = useState(false);
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const [modalShow, setModalShow] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const dropdownStyle = {
    opacity: isDropdownVisible ? 1 : 0,
    visibility: isDropdownVisible ? 'visible' : 'hidden',
    position: 'absolute',
    backgroundColor: '#f1f1f1',
    minWidth: '300px',
    boxShadow: '0px 8px 16px 0px rgba(0, 0, 0, 0.2)',
    zIndex: 1,
    left: isDropdownVisible ? '0' : '-250px',
    transition: 'left 2s ease-in-out, opacity 2s ease-in-out, visibility 2s ease-in-out',
  };

  const navigateRegister = () => {
    navigate("/register-pasien-online");
  };
  
  return (
    <div>
          {isTabletOrMobile ?
        <div>
        <div className='auth-wrapper auth-cover' >
          <MyVerticallyCenteredModal2
            show={modalShow2}
            onHide={() => setModalShow2(false)}
          />
      {loading && <LoaderHome />}
        
          <Row>
            <Col xl='12' sm='12'>
              {/* <Card.Img  style={{ width: "100%", height: "100%" }} /> */}
              <Card.ImgOverlay style={{ display: "flex", backgroundImage:"linear-gradient(white 50%,#0089FF)"  }}>
                <Col xl="12" style={{ backgroundColor: "#000", opacity: ".5", height: "100%", paddingBottom: "20px",paddingTop:"20px"}}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <img style={{ width: "50%" }} src={Logo_Dashboard} alt='Login Cover' />
                  </div>
                  <div style={{ color: "#505050", textAlign: "center", backgroundColor: "#84FFD3", height: "50px", display: "flex", justifyContent: "center", alignContent: "center", alignItems: "Center" }}>
                    Welcome To Signature Dental Care
                  </div>
                  <Form className='auth-login-form' style={{ marginTop: "40px", padding: "0px 20px" }}>
                    <div className='mb-2'>
                      <Label className='form-label' for='login-email' style={{ fontSize: "15px", fontWeight: "bold", color: "white", fontFamily: "sans-serif" }}>
                        Email
                      </Label>
                      <Input  type='email' placeholder='john@gratiajm.co.id' value={email} name="email" onChange={handleChange} />
                    </div>
                    <div className='mb-1'>
                      <div className='d-flex justify-content-between'>
                        <Label className='form-label' for='login-password' style={{ fontSize: "15px", fontFamily: "", fontWeight: "bold", color: "white", fontFamily: "sans-serif" }}>
                          Password
                        </Label>
                      </div>
                      <InputPasswordToggle placeholder="*********" className='input-group-merge' value={password} name="password" onChange={handleChange} />
                    </div>
                      
                    <div style={{ padding: "0px 0px" }}>
                      <div onClick={(e) => handleSubmit.mutate(e)} className="mt-4" type='submit' color='primary' block style={{ padding: "10px 0px", fontSize: "15px", borderRadius: "5px", backgroundColor:"#3680FF", color:"white", textAlign:"center" }}>
                        Sign in
                      </div>
                      </div>
                      
                  </Form>
                  
                  <div style={{ display: "flex", justifyContent: "end", marginTop: "20px", padding: "0px 20px", fontFamily: "initial", fontSize: "17px", color: "#90BDFF" }}>
                    <a onClick={navigateRegister} style={{ textDecoration: "underline", cursor: "pointer" }}>register pasien</a>
                  </div>
    
                  {/* <p className='text-center mt-4' style={{ fontFamily: "initial" }}>
                    <span className='me-25' style={{color:"#C7C7C7"}}>Forgot Password?</span>
                    <Link style={{ marginLeft: "2px" }}>
                      <span>Click</span>
                    </Link>
                  </p> */}
                </Col>
              </Card.ImgOverlay>
            </Col>
          </Row>
        </div>
      </div>
        :
      <div>
        <div className='auth-wrapper auth-cover' >
          <Row>
            {loading ?    
              <Col xl='12' sm='12'>
                 <MyVerticallyCenteredModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
                <Card.Img src={Caro1}  alt="Card image" style= {{width:"100%", height:"100vh", opacity: ".6"}}/>
                  <Card.ImgOverlay  style={{ marginTop: "0px", marginLeft: "0px", display:"flex", alignItems:"center"}}>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", position:"fixed", width:"100%" }}>
                        <div>
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <FadeLoader color={'#3F45FF'} />
                          </div>
                          <div>Please Wait.....</div>
                        </div>
                      </div>
                      <Col xl="12" style={{display:"",alignItems:"center", backgroundColor: "#000", opacity: ".5", width: "26%", marginTop: "0px", marginLeft: "40px", paddingBottom: "10px", borderRadius: "10px", boxShadow: "1px 1px 10px black", borderTopLeftRadius: "5px", borderTopRightRadius: "5px", }}>
                      <div style={{display:"flex", justifyContent:"center", backgroundColor:"white", borderTopLeftRadius: "5px", borderTopRightRadius:"5px",}}>
                        <img  style={{width:"55%",borderTopLeftRadius: "10px", borderTopRightRadius:"10px", backgroundColor:"white"}} src={Logo_Dashboard} alt='Login Cover' />
                      </div>
                      <div style={{color:"#505050",  textAlign:"center", backgroundColor:"#84FFD3", height:"35px",display:"flex", justifyContent:"center", alignContent:"center",alignItems:"Center"}}>
                         Welcome To Signature Dental Care
                      </div>
                        <Form className='auth-login-form' onSubmit={(e) => handleSubmit.mutate(e)} style={{ marginTop: "20px", padding: "0px 20px" }}>
                          <div className='mb-2'>
                            <Label className='form-label' for='login-email' style={{ fontSize: "15px", fontWeight: "bold", color: "white", fontFamily: "sans-serif" }}>
                              Email
                            </Label>
                            <Input autoFofcus type='email' placeholder='john@gratiajm.co.id' value={email} name="email" onChange={handleChange} />
                          </div>
                          <div className='mb-1'>
                            <div className='d-flex justify-content-between'>
                              <Label className='form-label' for='login-password' style={{ fontSize: "15px", fontFamily: "", fontWeight: "bold", color: "white", fontFamily: "sans-serif" }}>
                                Password
                              </Label>
                            </div>
                            <InputPasswordToggle placeholder="*********" className='input-group-merge' value={password} name="password" onChange={handleChange} />
                          </div>
      
                          <div style={{padding:"0px 0px"}}>
                          <Button className="mt-4" type='submit' color='primary' block style={{padding:"10px 0px", fontSize:"15px", borderRadius:"5px", }}>
                            Sign in
                          </Button>

                          <div className="mt-3" type='submit' color='' block style={{display:"flex", justifyContent:"center",padding:"10px 0px", fontSize:"12px", borderRadius:"5px", color:"white", border:"1px solid #8A8A8A"  }}>
                          Don't have an account?
                            <Link to="/register-pasien-online" style={{ marginLeft: "2px", textDecoration: "none" }}>
                              <span className="">Sign Up</span>
                            </Link>
                          </div>
                        </div>
                        </Form>
      
                        {/* <p className='text-center mt-3' style={{fontFamily:"initial", fontSize:"10px"}}>
                        <span className='me-25' style={{color:"#E1E1E1"}}>Forgot Password?</span>
                        <Link style={{marginLeft:"2px"}}>
                          <span>Click</span>
                        </Link>
                        </p> */}
                      </Col>
                    </Card.ImgOverlay>
              </Col>
                :
                <Col xl='12' sm='12'>  
                <Card.Img src={Caro1} alt="Card image" style={{ width: "100%", height: "100vh", }} />
                <Card.ImgOverlay style={{ marginTop: "0px", marginLeft: "0px", display:"flex", alignItems:"center"}}>
                  <Col xl="12" style={{ padding: "0px 0px", position: "fixed"}}>
                    <div style={{display: "flex", justifyContent: "end", marginBottom: "350px" }}>
                      <Dropdown />  
                    </div>
                  </Col>
                    <Col xl="12" style={{display:"",alignItems:"center", backgroundColor: "#000", opacity: ".5", width: "26%", marginTop: "0px", marginLeft: "40px", paddingBottom: "10px", borderRadius: "10px", boxShadow: "1px 1px 10px black", borderTopLeftRadius: "5px", borderTopRightRadius: "5px", }}>
                      <div style={{display:"flex", justifyContent:"center", backgroundColor:"white", borderTopLeftRadius: "5px", borderTopRightRadius:"5px",}}>
                        <img  style={{width:"55%",borderTopLeftRadius: "10px", borderTopRightRadius:"10px", backgroundColor:"white"}} src={Logo_Dashboard} alt='Login Cover' />
                      </div>
                      <div style={{color:"#505050",  textAlign:"center", backgroundColor:"#84FFD3", height:"35px",display:"flex", justifyContent:"center", alignContent:"center",alignItems:"Center"}}>
                        Welcome To Signature Dental Care
                      </div>
                      <Form className='auth-login-form' onSubmit={(e) => handleSubmit.mutate(e)}  style={{ marginTop:"10px", padding:"0px 20px"}}>
                        <div className='mb-2'>
                          <Label className='form-label' for='login-email' style={{fontSize:"15px", fontWeight:"bold", color:"white", fontFamily:"sans-serif"}}>
                            Email
                          </Label>
                          <Input autoFofcus type='email' placeholder='john@gratiajm.co.id'  value={email} name="email" onChange={handleChange}/>
                        </div>
                        <div className='mb-2'>
                          <div className='d-flex justify-content-between'>
                            <Label className='form-label' for='login-password' style={{fontSize:"15px", fontFamily:"", fontWeight:"bold", color:"white", fontFamily:"sans-serif"}}>
                              Password
                            </Label>
                          </div>
                          <InputPasswordToggle placeholder="*********" className='input-group-merge' value={password} name="password" onChange={handleChange} />
                        </div>

                        <div style={{padding:"0px 0px"}}>
                          <Button className="mt-4" type='submit' color='primary' block style={{padding:"10px 0px", fontSize:"15px", borderRadius:"5px", }}>
                            Sign in
                          </Button>

                          <div className="mt-3" type='submit' color='' block style={{display:"flex", justifyContent:"center",padding:"10px 0px", fontSize:"12px", borderRadius:"5px", color:"white", border:"1px solid #8A8A8A"  }}>
                          Don't have an account?
                            <Link to="/register-pasien-online" style={{ marginLeft: "2px", textDecoration: "none" }}>
                              <span className="">Sign Up</span>
                            </Link>
                          </div>
                        </div>
                      </Form>
                   
                      {/* <p className='text-center mt-3' style={{fontFamily:"initial", fontSize:"10px"}}>
                        <span className='me-25' style={{color:"#E1E1E1"}}>Forgot Password?</span>
                        <Link style={{marginLeft:"2px"}}>
                          <span>Click</span>
                        </Link>
                        </p> */}
                        
                      </Col>
                  </Card.ImgOverlay>
              </Col>
                }
            </Row>
        </div>
      </div>
        }
    </div>
  );
}
