import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaClosedCaptioning, FaTimes, FaTimesCircle} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signature logo.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import "../../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import { FadeLoader, HashLoader, ScaleLoader } from "react-spinners";
import LoaderAction from "../../Loader/LoaderAction"
import LogoDatePicker from "../../../assets/signature/logo-date-pick.png";

export default function ModalRoleAdd(props) {
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const token = localStorage.getItem("token");
  const [getDataDokter, setGetDataDokter] = useState([]);
  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [getData, setGetData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [idRoleDokter, setIdRoleDokter] = useState("");
  const [nameRoleDoctor, setNameRoleDoktor] = useState("Doctor");
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`user-service/users?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&id_role=${idRoleDokter}&name_role=${nameRoleDoctor}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  useEffect(() => {
    GetResponseData()
  }, [])

  const [form, setForm] = useState({
    id_user: "",
    ci_date: "",
    ci_time: "",
    co_date: "",
    co_time: "",
  });

  const [form2, setForm2] = useState({
    id_user: "",
    ci_date: "",
    ci_time: "",
    co_date: "",
    co_time: "",
  });

  useEffect(() => {
    if (form2?.id_user !== "" && form2?.ci_date !== "" && form2?.co_date !== "" 
    && form2?.ci_time !== "" && form2?.co_time !== "") {
      const startDate = new Date(form2.ci_date);
      const endDate = new Date(form2.co_date);
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
  
      const newForm3 = [];
      for (let i = 0; i <= diffDays; i++) {
        const date = new Date(startDate);
        date.setDate(startDate.getDate() + i);
        const ciTime = `${form2.ci_time}`; 
        const coTime = `${form2.co_time}`; 
        newForm3.push({
          id_user: form2.id_user,
          ci_date: date.toISOString().slice(0, 10),
          ci_time: ciTime,
          co_date: date.toISOString().slice(0, 10),
          co_time: coTime,
        });
      }
      setForm3(newForm3);
    }
  }, [form2]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange2 = (e) => {
    setForm2({
      ...form2,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      e.preventDefault();
      setLoading(true)
    
      // Insert data for login process
      const response = await API.post("user-service/schedules/store", {
        id_user: form?.id_user,
        ci_date: form?.ci_date,
        ci_time: form?.ci_time,
        co_date: form?.co_date,
        co_time: form?.co_time,
      }, fetchParams);
  
      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })
        props.GetResponseData()
        props.onHide()
        setLoading(false)
      }

    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false)
    }
  };

  const [isChecked, setIsChecked] = useState(false); 

  const toggleSwitch = () => {
    setIsChecked(prevState => !prevState);
  };

  const [tooltipIndex, setTooltipIndex] = useState(null);
  const [form3, setForm3] = useState([]);

  const updateForm3AtIndex = (index, ci_time, co_time) => {
    setForm3(prevForm3 => {
      const updatedForm3 = [...prevForm3];
      updatedForm3[index] = {
        ...updatedForm3[index],
        ci_time,
        co_time
      };
      return updatedForm3;
    });
  };

  const handleTooltipToggle = index => {
    setTooltipIndex(tooltipIndex === index ? null : index);
  };

  const handleDelete = index => {
    setForm3(prevForm3 => prevForm3.filter((_, i) => i !== index));
  };

  const viewToolTip = (user, index) => {
    return (
      <div style={{width: "150px"}}>
        <div style={{display:"flex"}}>
          <div style={{flex:"80%", display:"flex", justifyContent:"start"}}>
            {user.ci_date}
          </div>
          <div style={{flex:"20%", display:"flex", justifyContent:"end"}}>
            <FaTimesCircle style={{cursor:"pointer"}}/>
          </div>
        </div>
        <div className="mt-1" style={{display:"flex", justifyContent:"center"}}>
          <select
            value={user.ci_time}
            onChange={e => updateForm3AtIndex(index, e.target.value, user.co_time)}
            name="ci_time"
            style={{
              cursor: "pointer",
              width: "100%",
              textAlign: "center",
              border: "1px solid #C9C9C9",
              outline: "none",
              height: "37.5px",
              borderRadius: "5px",
            }}
            onClick={e => e.stopPropagation()} // Tambahkan ini untuk mencegah penutupan tooltip
          >
            <option value="09:00:00">09:00</option>
              <option value="09:30:00">09:30</option>
              <option value="10:00:00">10:00</option>
              <option value="10:30:00">10:30</option>
              <option value="11:00:00">11:00</option>
              <option value="11:30:00">11:30</option>
              <option value="12:00:00">12:00</option>
              <option value="12:30:00">12:30</option>
              <option value="13:00:00">13:00</option>
              <option value="13:30:00">13:30</option>
              <option value="14:00:00">14:00</option>
              <option value="14:30:00">14:30</option>
              <option value="15:00:00">15:00</option>
              <option value="15:30:00">15:30</option>
              <option value="16:00:00">16:00</option>
              <option value="16:30:00">16:30</option>
              <option value="17:00:00">17:00</option>
              <option value="17:30:00">17:30</option>
              <option value="18:00:00">18:00</option>
              <option value="18:30:00">18:30</option>
              <option value="19:00:00">19:00</option>
              <option value="19:30:00">19:30</option>
              <option value="20:00:00">20:00</option>
              <option value="20:30:00">20:30</option>
              <option value="21:00:00">21:00</option>
              <option value="21:30:00">21:30</option>
              <option value="22:00:00">22:00</option>  
          </select>
        </div>
        <div className="mt-2" style={{display:"flex", justifyContent:"center"}}>    
          <select
            value={user.co_time}
            onChange={e => updateForm3AtIndex(index, user.ci_time, e.target.value)}
            name="co_time"
            style={{
              cursor: "pointer",
              width: "100%",
              textAlign: "center",
              border: "1px solid #C9C9C9",
              outline: "none",
              height: "37.5px",
              borderRadius: "5px",
            }}
            onClick={e => e.stopPropagation()} // Tambahkan ini untuk mencegah penutupan tooltip
            >
              <option value="09:00:00">09:00</option>
              <option value="09:30:00">09:30</option>
              <option value="10:00:00">10:00</option>
              <option value="10:30:00">10:30</option>
              <option value="11:00:00">11:00</option>
              <option value="11:30:00">11:30</option>
              <option value="12:00:00">12:00</option>
              <option value="12:30:00">12:30</option>
              <option value="13:00:00">13:00</option>
              <option value="13:30:00">13:30</option>
              <option value="14:00:00">14:00</option>
              <option value="14:30:00">14:30</option>
              <option value="15:00:00">15:00</option>
              <option value="15:30:00">15:30</option>
              <option value="16:00:00">16:00</option>
              <option value="16:30:00">16:30</option>
              <option value="17:00:00">17:00</option>
              <option value="17:30:00">17:30</option>
              <option value="18:00:00">18:00</option>
              <option value="18:30:00">18:30</option>
              <option value="19:00:00">19:00</option>
              <option value="19:30:00">19:30</option>
              <option value="20:00:00">20:00</option>
              <option value="20:30:00">20:30</option>
              <option value="21:00:00">21:00</option>
              <option value="21:30:00">21:30</option>
              <option value="22:00:00">22:00</option>  
          </select>
        </div>
        <button className="mt-2 botton_delete" onClick={() => handleDelete(index)}>
            Hapus 
        </button>
      </div>
    );
  };

  const handleSubmit2 = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const response = await API.post("/user-service/schedules/monthly/store", {
        id_user: form2?.id_user,
        schedules: form3
      }, fetchParams);
  
      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        });
        props.GetResponseData();
        props.onHide();
        setLoading(false);
      }

    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false);
    }
  };

  return (
  <div>
    {loading && <LoaderAction/>}
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered 
      style={{ fontFamily: "sans-serif", border: "none" }}>  
      <div style={{width: "100%",display:"flex",padding:"10px 0px", backgroundColor:"#29B8FF"}}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", 
          paddingLeft:"10px", color:"white", fontWeight:"600"}}>
          Tambah Schedules Dokter
        </div> 
        <div  style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",justifyContent:"center", color:"white"}}>
          <FaTimes onClick={() => setProopsData(props.onHide)} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px",
        border:"none",backgroundImage:"transparent" }}>
        <Form onSubmit={(e) => handleSubmit.mutate(e)} className="mt-3" >    
        <div style={{display:"flex", justifyContent:"start"}}> 
          <div className="mr-1" style={{display:"flex", alignItems:"center", fontSize:"15px", fontWeight:"bold", 
          fontStyle:"revert"}}>
            Bulanan
          </div>
          <div style={{display:"flex", alignItems:"center"}}>
            <label className="toggle-switch">
              <input type="checkbox" checked={isChecked} onChange={toggleSwitch} />
              <span className="switch"></span>
            </label>
          </div>
        </div>

        {isChecked === false ? 
          <>
            <div className="mt-3" style={{ display: "flex"}}>
              <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
                Pilih Dokter
              </div>
              <div style={{ flex: "60%", display:"flex"}}>
                <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
                <select className="form-select" aria-label="Default select example" onChange={handleChange} 
                value={form?.id_user}  name="id_user" style={{ textAlign:"", cursor:"pointer"}}>
                    <option value="" hidden>Select</option>
                    {getData.map((user,index) => (
                      <option value={user?.id} style={{textAlign: "" }}>{user?.firstname} {user?.lastname}</option>                         
                    ))}
                </select>
              </div>
            </div>

            <div className="mt-4" style={{ display: "flex"}}>
              <div style={{flex:"5%"}}>
                <p>Tanggal Dan Waktu Checkin</p>
              </div>
              <div style={{ flex: "60%", display:"flex"}}>
                <p style={{marginRight:"5px", height:"100%"}}>:</p>
                <input type="date" name="ci_date" onChange={handleChange}  style={{
                      width: "32%",
                      marginRight:"10px",
                      border: "1px solid #C9C9C9",
                      outline: "",
                      height: "37.5px",
                      borderRadius: "5px",
                      paddingLeft: "5px",
                      backgroundImage: `url(${LogoDatePicker})`,
                      backgroundPosition: 'calc(100% - 10px) center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: '25px',
                      }}/>
                    <select onChange={handleChange} name="ci_time" style={{
                      cursor: "pointer",
                      width: "20%",
                      textAlign:"center",
                      border: "1px solid #C9C9C9",
                      outline: "",
                      height: "37.5px",
                      borderRadius: "5px",
                      paddingLeft: "5px",
                    }}>
                  <option value="" hidden>Jam</option>
                  <option value="09:00:00">09:00</option>
                  <option value="09:30:00">09:30</option>
                  <option value="10:00:00">10:00</option>
                  <option value="10:30:00">10:30</option>
                  <option value="11:00:00">11:00</option>
                  <option value="11:30:00">11:30</option>
                  <option value="12:00:00">12:00</option>
                  <option value="12:30:00">12:30</option>
                  <option value="13:00:00">13:00</option>
                  <option value="13:30:00">13:30</option>
                  <option value="14:00:00">14:00</option>
                  <option value="14:30:00">14:30</option>
                  <option value="15:00:00">15:00</option>
                  <option value="15:30:00">15:30</option>
                  <option value="16:00:00">16:00</option>
                  <option value="16:30:00">16:30</option>
                  <option value="17:00:00">17:00</option>
                  <option value="17:30:00">17:30</option>
                  <option value="18:00:00">18:00</option>
                  <option value="18:30:00">18:30</option>
                  <option value="19:00:00">19:00</option>
                  <option value="19:30:00">19:30</option>
                  <option value="20:00:00">20:00</option>
                  <option value="20:30:00">20:30</option>
                  <option value="21:00:00">21:00</option>
                  <option value="21:30:00">21:30</option>
                  <option value="22:00:00">22:00</option>   
                </select>                
              </div>
            </div>   
                
            <div className="" style={{ display: "flex"}}>
              <div style={{flex:"5%"}}>
                <p>Tanggal Dan Waktu Checkout</p>
              </div>
              <div style={{ flex: "60%", display:"flex"}}>
                <p style={{marginRight:"5px", height:"100%"}}>:</p>
                <input type="date" name="co_date" onChange={handleChange}  style={{
                      width: "32%",
                      marginRight:"10px",
                      border: "1px solid #C9C9C9",
                      outline: "",
                      height: "37.5px",
                      borderRadius: "5px",
                      paddingLeft: "5px",
                      backgroundImage: `url(${LogoDatePicker})`,
                      backgroundPosition: 'calc(100% - 10px) center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: '25px',
                      }}/>
                <select onChange={handleChange}  name="co_time" style={{
                      cursor: "pointer",
                      width: "20%",
                      textAlign:"center",
                      border: "1px solid #C9C9C9",
                      outline: "",
                      height: "37.5px",
                      borderRadius: "5px",
                      paddingLeft: "5px",
                    }}>
                  <option value="" hidden>Jam</option>
                  <option value="09:00:00">09:00</option>
                  <option value="09:30:00">09:30</option>
                  <option value="10:00:00">10:00</option>
                  <option value="10:30:00">10:30</option>
                  <option value="11:00:00">11:00</option>
                  <option value="11:30:00">11:30</option>
                  <option value="12:00:00">12:00</option>
                  <option value="12:30:00">12:30</option>
                  <option value="13:00:00">13:00</option>
                  <option value="13:30:00">13:30</option>
                  <option value="14:00:00">14:00</option>
                  <option value="14:30:00">14:30</option>
                  <option value="15:00:00">15:00</option>
                  <option value="15:30:00">15:30</option>
                  <option value="16:00:00">16:00</option>
                  <option value="16:30:00">16:30</option>
                  <option value="17:00:00">17:00</option>
                  <option value="17:30:00">17:30</option>
                  <option value="18:00:00">18:00</option>
                  <option value="18:30:00">18:30</option>
                  <option value="19:00:00">19:00</option>
                  <option value="19:30:00">19:30</option>
                  <option value="20:00:00">20:00</option>
                  <option value="20:30:00">20:30</option>
                  <option value="21:00:00">21:00</option>
                  <option value="21:30:00">21:30</option>
                  <option value="22:00:00">22:00</option>   
                </select> 
                                          
              </div>
            </div>

            <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"end" }}>
              <div>
                <Button className="mt-4" onClick={handleSubmit} color='primary' style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
                  Tambahkan
                </Button>
              </div>
            </div>
          </>
          :
          <>
            <div className="mt-3" style={{ display: "flex"}}>
              <div style={{flex:"5%", display:"flex",alignItems:"center"}}>
                Pilih Dokter
              </div>
              <div style={{ flex: "60%", display:"flex"}}>
                <p style={{marginRight:"5px", height:"100%", display:"flex", alignItems:"center"}}>:</p>
                <select className="form-select" aria-label="Default select example" onChange={handleChange2} 
                  value={form2?.id_user}  name="id_user" style={{ textAlign:"", cursor:"pointer"}}>
                    <option value="" hidden>Select</option>
                    {getData.map((user,index) => (
                      <option value={user?.id} style={{textAlign: "" }}>{user?.firstname} {user?.lastname}</option>                         
                    ))}
                </select>
              </div>
            </div>

            <div className="mt-4" style={{ display: "flex"}}>
              <div style={{flex:"5%"}}>
                <p>Tanggal Masuk</p>
              </div>
              <div style={{ flex: "60%", display:"flex"}}>
                <p style={{marginRight:"5px", height:"100%"}}>:</p>
                  <div>
                    <input type="date" name="ci_date" onChange={handleChange2} style={{
                      borderRadius:"none",
                      border: "1px solid #C9C9C9",
                      borderTopLeftRadius:"5px",
                      borderTopRightRadius:"5px",
                      outline: "",
                      height: "37.5px",
                      paddingLeft: "5px",
                      backgroundImage: `url(${LogoDatePicker})`,
                      backgroundPosition: 'calc(100% - 10px) center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: '25px',
                    }}/>
                    <div style={{display:"flex", justifyContent:"center", fontSize:"12px", color:"white", fontWeight:"bold", 
                    border:"1px solid #C9C9C9", backgroundColor:"#29B8FF",fontFamily:"Poppins",borderBottomLeftRadius:"5px",
                    borderBottomRightRadius:"5px", borderTop:"none"}}>
                      Awal
                    </div>
                  </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                  <div>
                    <input type="date" name="co_date" onChange={handleChange2} style={{
                      border: "1px solid #C9C9C9",
                      outline: "",
                      borderTopLeftRadius:"5px",
                      borderTopRightRadius:"5px",
                      height: "37.5px",
                      borderRadius: "none",
                      paddingLeft: "5px",
                      backgroundImage: `url(${LogoDatePicker})`,
                      backgroundPosition: 'calc(100% - 10px) center',
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: '25px',
                    }}/>
                    <div style={{display:"flex", justifyContent:"center", fontSize:"12px", color:"white", fontWeight:"bold", 
                      border:"1px solid #C9C9C9", backgroundColor:"#29B8FF",fontFamily:"Poppins",borderBottomLeftRadius:"5px",
                      borderBottomRightRadius:"5px", borderTop:"none"}}>
                      Akhir
                    </div>
                  </div>          
              </div>
            </div>

            <div className="mt-4" style={{ display: "flex"}}>
              <div style={{flex:"5%"}}>
                <p>Waktu Masuk</p>
              </div>
              <div style={{ flex: "60%", display:"flex"}}>
                <p style={{marginRight:"5px", height:"100%"}}>:</p>
                <select onChange={handleChange2} name="ci_time" style={{
                    cursor: "pointer",
                    width: "20%",
                    textAlign:"center",
                    border: "1px solid #C9C9C9",
                    outline: "",
                    height: "37.5px",
                    borderRadius: "5px",
                    paddingLeft: "5px",
                    marginRight:"10px",
                  }}>
                  <option value="" hidden>Masuk</option>
                  <option value="09:00:00">09:00</option>
                  <option value="09:30:00">09:30</option>
                  <option value="10:00:00">10:00</option>
                  <option value="10:30:00">10:30</option>
                  <option value="11:00:00">11:00</option>
                  <option value="11:30:00">11:30</option>
                  <option value="12:00:00">12:00</option>
                  <option value="12:30:00">12:30</option>
                  <option value="13:00:00">13:00</option>
                  <option value="13:30:00">13:30</option>
                  <option value="14:00:00">14:00</option>
                  <option value="14:30:00">14:30</option>
                  <option value="15:00:00">15:00</option>
                  <option value="15:30:00">15:30</option>
                  <option value="16:00:00">16:00</option>
                  <option value="16:30:00">16:30</option>
                  <option value="17:00:00">17:00</option>
                  <option value="17:30:00">17:30</option>
                  <option value="18:00:00">18:00</option>
                  <option value="18:30:00">18:30</option>
                  <option value="19:00:00">19:00</option>
                  <option value="19:30:00">19:30</option>
                  <option value="20:00:00">20:00</option>
                  <option value="20:30:00">20:30</option>
                  <option value="21:00:00">21:00</option>
                  <option value="21:30:00">21:30</option>
                  <option value="22:00:00">22:00</option>   
                </select>    
                <select onChange={handleChange2} name="co_time" style={{
                      cursor: "pointer",
                      width: "20%",
                      textAlign:"center",
                      border: "1px solid #C9C9C9",
                      outline: "",
                      height: "37.5px",
                      borderRadius: "5px",
                      paddingLeft: "5px",
                    }}>
                  <option value="" hidden>Keluar</option>
                  <option value="09:00:00">09:00</option>
                  <option value="09:30:00">09:30</option>
                  <option value="10:00:00">10:00</option>
                  <option value="10:30:00">10:30</option>
                  <option value="11:00:00">11:00</option>
                  <option value="11:30:00">11:30</option>
                  <option value="12:00:00">12:00</option>
                  <option value="12:30:00">12:30</option>
                  <option value="13:00:00">13:00</option>
                  <option value="13:30:00">13:30</option>
                  <option value="14:00:00">14:00</option>
                  <option value="14:30:00">14:30</option>
                  <option value="15:00:00">15:00</option>
                  <option value="15:30:00">15:30</option>
                  <option value="16:00:00">16:00</option>
                  <option value="16:30:00">16:30</option>
                  <option value="17:00:00">17:00</option>
                  <option value="17:30:00">17:30</option>
                  <option value="18:00:00">18:00</option>
                  <option value="18:30:00">18:30</option>
                  <option value="19:00:00">19:00</option>
                  <option value="19:30:00">19:30</option>
                  <option value="20:00:00">20:00</option>
                  <option value="20:30:00">20:30</option>
                  <option value="21:00:00">21:00</option>
                  <option value="21:30:00">21:30</option>
                  <option value="22:00:00">22:00</option>   
                </select>                
              </div>
            </div>

            <div>
              {form3.length !== 0 && 
                <div className="mt-4" style={{ display: "flex" }}>
                  <div style={{ flex: "11%" }}>
                    <p>Jadwal</p>
                  </div>
                  <div style={{ flex: "60%", display: "flex" }}>
                    <div>
                      <Row style={{ border: "" }}>
                        {form3.map((user, index) => (
                          <Col xl='2' sm='12'
                            key={index}
                            onClick={() => handleTooltipToggle(index)}
                            className="mr-1 mb-1 mapping-schedule">
                            <div style={{display:"flex", justifyContent:"center", color:"#29B8FF"}}>
                              {user.ci_date.split('-')[2]}
                            </div>
                            {tooltipIndex === index && (
                              <div style={{
                                position: "absolute",
                                top: "100%",
                                left: "50%",
                                transform: "translateX(-50%)",
                                backgroundColor: "white",
                                border: "1px solid #ddd",
                                borderRadius: "5px",
                                padding: "10px",
                                zIndex: 10,
                                cursor:"default"
                              }}>
                                {viewToolTip(user, index)}
                              </div>
                            )}
                          </Col>
                        ))}
                      </Row>
                    </div>

                  </div>
                </div>
              }
            </div>
          
            <div style={{ padding: "0px 0px", marginTop: "0px", display:"flex", justifyContent:"end" }}>
              <div>
                <Button className="mt-4" onClick={handleSubmit2} color='primary' style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px"}}>
                  Tambahkan
                </Button>
              </div>
            </div>
          </>
        }
        </Form> 
      </Modal.Body>
      {/* <Modal.Footer>
        <div style={{ display: "flex" }}>
          <Button onClick={props.onHide} style={{marginRight:"8px"}}>Close</Button>
        </div>
      </Modal.Footer> */}
    </Modal>
    </div>
   
    );
}
  