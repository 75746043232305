import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaShare, FaUndoAlt} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../assets/signature/signature logo.png"
import Logo_Batik from "../../assets/signature/logo-batik.png"
import "bulma/css/bulma.css";
import { useMutation } from "react-query";
import "../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
// Modal Role
import ModalAddMedis from "./ModalAdmin/ModalAdd"
import ModalUpdateMedis from "./ModalAdmin/ModalUpdate"
import { ScaleLoader } from "react-spinners";
import LoaderHome from "../Loader/LoaderHome"
import Select from 'react-select';
import axios from "axios";
import DatePicker from 'react-date-picker';


export default function Login() {
  // let navigate = useNavigate();
  // useEffect(() => {
  //   if (localStorage.getItem("token") == null || undefined) {
  //       navigate("/");
  //     }
  // }, []);
  document.title = "SDC Apps";
  const navigate = useNavigate()
  const [getData, setGetData] = useState([]);
  const [pagination,setPagination] = useState([])
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const [rows, setRows] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const token = localStorage.getItem("token");
  const [idDelete, setIdDelete] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [show, setShow] = useState(false);
  const [search,setSearch] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dataMap, setDataMap] = useState("");
  const [dataSearch, setDataSearch] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  // Responsive to mobile or dekstop
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 980px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  // modal role add 
  const [id, setId] = useState();
  const [idVendorUpdate, setIdVendorUpdate] = useState();
  const [idUnitUpdate, setIdUnitUpdate] = useState();
  const [idCategoryInventoryUpdate, setIdCategoryInventoryUpdate] = useState();
  const [nameInventoryUpdate, setNameInventoryUpdate] = useState();
  const [firstStockQtyUpdate, setFirstStockQtyUpdate] = useState();
  const [firstStockExpiredUpdate, setFirstStockExpiredUpdate] = useState();
  const [firstStockPriceUpdate, setFirstStockPriceUpdate] = useState();
  const [noteUpdate, setNoteUpdate] = useState();
  const [nameUpdate, setNameUpdate] = useState();
  const [addressUpdate, setAddressUpdate] = useState();
  const [cityUpdate, setCityUpdate] = useState();
  const [provinceUpdate, setProvinceUpdate] = useState();
  const [phoneNumberUpdate, setPhoneNumberUpdate] = useState();
  const [urlUpdate, setUrlUpdate] = useState();
  const [iconUpdate, setIconUpdate] = useState();
  const [modalAdd, setModalAdd] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [getDataTreatment, setGetDataTreatment] = useState([]);
  const [getDataCode, setGetCode] = useState([]);
  const [selectedTreatment, setSelectedTreatment] = useState(null);  
  const [getDataItem, setGetDataItem] = useState([
    {
      id_inventory: "",
      Description: "",
      Qty: "",
      Price: "",
      Tax: "",
      LineTotal: ""
    }
  ]);

  const [form, setForm] = useState({
    id_inventory: "",
    po_date: "",
    due_date: "",
    description: ""
  });

  console.log(form)

  const handleAddClick = (e) => {

    // Buat elemen baru dengan nilai default dari dataItem
    const newInputItem = {
      id_inventory: "",
      Description: "",
      Qty: "",
      Price: "",
      Tax: "",
      LineTotal: ""
    };
  
    // Push elemen baru ke inputList
    setGetDataItem((prevInputList) => {
      return [...prevInputList, newInputItem];
    });     
  };

  // handle click event of the Remove button


  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleTreatmentChange = (selectedOption) => {
    setSelectedTreatment(selectedOption);
    setForm({
      ...form,
      id_inventory: selectedOption.value,
    });
  };


  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const GetResponseDataTreatment = async () => {
    try {
      // e.preventDefault();
      const response = await API.get(`transaction-service/inventory-vendors?page=${1}&limit=${10000}&ascending=${1}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataTreatment(response.data.data)
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`transaction-service/inventorys?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&search=${search}`,fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data)
        setPage(response.data.pagination.current_page);
        setPages(response.data.pagination.total_pages);
        setRows(response.data.pagination.total);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const generateCode = async () => {
    try {
      // e.preventDefault();
      // setLoading(true)
      const response = await API.post(`user-service/master-codes/generate`,
        {
          type: "purchase_request",
          object_code: ""
        },
        fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetCode(response.data.data)
        // setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  useEffect(() => {
    generateCode()
    GetResponseData()
    GetResponseDataTreatment()
  }, [page])

  const deleteById = async (id) => {
    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: `Menghapus data ini`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus'
    }).then( async (result) => {
      if(result.isConfirmed) {
        const response =  await API.delete(`transaction-service/inventorys/${id}`,fetchParams);
        if (response.data.error == false) {
          GetResponseData()
          swal({
            title: 'Success',
            text: "Your data has been successfully deleted",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
        }  
      }
    })
  };

  const changePage = ({ selected }) => {
    setPage(selected+1);
    if (selected === 10) {
      setMsg(
        ""
      );
    } else {
      setMsg("");
    }
  };
 
  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
  };

  const buttonRefresh = () => {
    window.location.reload();
  }
  
  const viewModalAdd = () => {
    setModalAdd(true)
  }

  const viewModalUpdate = (id, id_vendor, id_unit, id_category_inventory, name_inventory, first_stock_qty, first_stock_expired, first_stock_price, note) => {
    setModalUpdate(true)
    setId(id)
    setIdVendorUpdate(id_vendor)
    setIdUnitUpdate(id_unit)
    setIdCategoryInventoryUpdate(id_category_inventory)
    setNameInventoryUpdate(name_inventory)
    setFirstStockQtyUpdate(first_stock_qty)
    setFirstStockExpiredUpdate(first_stock_expired)
    setFirstStockPriceUpdate(first_stock_price)
    setNoteUpdate(note)
  }

  function formatToRupiah(number) {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0, // Set minimum fraction digits to 0
      maximumFractionDigits: 0, // Set maximum fraction digits to 0
    }).format(number);
  }

  const navigateRequest = () => {
    navigate('/purchase-requests')
  }

  const navigateOrder = () => {
    navigate('/purchase-orders')
  }

  const navigateInvoice = () => {
    navigate('/purchase-invoices')
  }
  
  const navigateRequestAdd = () => {
    navigate('/purchase-requests/add')
  }

  return (
    <div style={{ backgroundColor: "", marginTop: "15px", marginLeft: "10px", marginRight: "10px", boxShadow: "2px 2px 10px #BFBFBF" }}>
      {modalAdd && <ModalAddMedis GetResponseData={GetResponseData} show={modalAdd} onHide={() => setModalAdd(false)} />}
      {modalUpdate && <ModalUpdateMedis GetResponseData={GetResponseData} id={id} idVendorUpdate={idVendorUpdate} idUnitUpdate={idUnitUpdate} idCategoryInventoryUpdate={idCategoryInventoryUpdate} nameInventoryUpdate={nameInventoryUpdate} firstStockQtyUpdate={firstStockQtyUpdate} firstStockExpiredUpdate={firstStockExpiredUpdate} firstStockPriceUpdate={firstStockPriceUpdate} noteUpdate={noteUpdate} show={modalUpdate} onHide={() => setModalUpdate(false)} />}
      {loading && <LoaderHome />} 
      
      {isTabletOrMobile ? 
        <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "", padding: "0px 0px 10px 0px" }}>
          <Col xl="6" style={{fontSize:"16px",display:"flex", justifyContent:"center", alignItems:"center", padding:"7px", color:"#001F8B", backgroundColor:"#E9EFFF"}}>
              <FaAddressBook style={{marginRight:"5px"}}/>List Inventory
          </Col>
          <Col className="mt-2" xl="6" style={{ display: "flex", justifyContent:"end", paddingRight:"5px" }}>
            <div onClick={viewModalAdd} style={{display:"flex",alignItems:"center",marginRight:"5px",backgroundColor:"#3D64FF",color:"white",padding:"0px 12px", borderRadius:"3px", cursor:"pointer", fontSize:"12px", cursor:"pointer"}}>
              <FaPlus/>
            </div>
            <div onClick={buttonRefresh} style={{ height: "100%", marginRight: "5px", paddingTop: "0px", backgroundColor: "white", padding: "10px 10px", borderRadius: "2px", cursor: "pointer", border: "1px solid #DEDEDE" }}>
              <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height:"100%", color:"#3D64FF" }} />
            </div>
            <form onSubmit={searchData} style={{display:"flex", paddingRight:"0px"}}>
              <div style={{marginRight:"5px",borderRadius:"3px"}}>
                <input value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  className="focused"
                  style={{backgroundColor:"#E9E9E9", border:"none",height:"100%"}}
                  type="text"
                  placeholder="testing"
                />
              </div>
                <button type="submit"  style={{ border: "none", backgroundColor: "#3D64FF", color: "white", display: "flex", borderRadius:"2px", fontWeight:"600", fontSize:"12px",alignItems:"center", padding:"0px 10px" }}>
                  Search
                </button>
            </form>
          </Col>
        </div>
          :
        <div style={{ paddingLeft: "0px", width: "100%",  display: "flex", padding: "10px 0px", backgroundColor:"white" }}>
          <div style={{flex:"50%",fontSize:"16px",display:"flex", alignItems:"center", paddingLeft:"10px", color:"#001F8B"}}>
            <div style={{backgroundColor:"#3259F3",marginRight: "5px", padding:"5px 10px", borderRadius:"5px" }}>
              <FaShare style={{  transform: 'scaleX(-1)' , color:"white", fontSize:"22px"}} />
            </div>
            <div style={{fontSize:"18px", fontFamily:"poppins", color:"#737373"}}>
              Create Purchase Request
            </div>
          </div>
        </div>  
      }

      <div style={{ display: "flex" , backgroundColor:"white", padding:"10px 10px"}}>
        <div style={{flex:"50%", display:"flex",alignItems:"center", marginRight:"10px"}}>
          <div style={{ width: "100%" }}>
            <div style={{fontSize:"12px", color:"#737373", fontStyle:"", fontWeight:"bold"}}>No.PR</div>
            <input  className="focused"  type='text' name="estimate_price" value={getDataCode?.number} 
              style={{ width: "100%",height:"37.5px", textAlign: "center",border:"1px solid #C9C9C9", borderRadius:"5px" }} disabled/>
          </div>
        </div>
        <div className="mr-2" style={{flex:"50%", width: "100%" }}>
          <div style={{ fontSize: "12px", color: "#737373", fontStyle: "", fontWeight: "bold", }}>Pilih Supplier</div>
            <Select
              onMouseEnter={getDataTreatment.length === 0 ? GetResponseDataTreatment : null}
              // className="form-select"
              value={selectedTreatment}
              onChange={handleTreatmentChange}
              options={getDataTreatment.map(user => ({ value: user.id, label: user.name }))}
              placeholder="- select -"
            />
        </div>
      </div>
      
      <div style={{ display: "flex" , backgroundColor:"white", padding:"10px 10px"}}>
        <div style={{flex:"50%", display:"flex",alignItems:"center", marginRight:"10px"}}>
          <div style={{ width: "100%" }}>
            <div style={{fontSize:"12px", color:"#737373", fontStyle:"", fontWeight:"bold"}}>Tanggal PR</div>
            <input type='date' name="po_date" value={form?.po_date} onChange={handleChange}
                style={{ 
                width: "100%", 
                height: "37.5px", 
                textAlign: "", 
                border: "1px solid #C9C9C9", 
                borderRadius: "5px", 
                paddingLeft: "10px", 
                color: "#737373",
                cursor:"pointer",
                backgroundImage: 'url("https://cdn-icons-png.flaticon.com/512/5777/5777935.png")',
                backgroundPosition: 'calc(100% - 10px) center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '25px',
                }}
              />
          </div>
        </div>
        <div style={{flex:"50%", display:"flex",alignItems:"center", marginRight:"10px"}}>
          <div style={{ width: "100%" }}>
            <div style={{fontSize:"12px", color:"#737373", fontStyle:"", fontWeight:"bold"}}>Due Date</div>
            <input type='date' name="due_date" value={form?.due_date} onChange={handleChange}
              style={{ 
              width: "100%", 
              height: "37.5px", 
              textAlign: "", 
              border: "1px solid #C9C9C9", 
              borderRadius: "5px", 
              paddingLeft: "10px", 
              color: "#737373",
              cursor:"pointer",
              backgroundImage: 'url("https://www.iconarchive.com/download/i103365/paomedia/small-n-flat/calendar.ico")',
              backgroundPosition: 'calc(100% - 10px) center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: '25px',
              }}
            />
          </div>
        </div>
      </div>

      <div style={{ display: "flex", backgroundColor: "white", padding: "10px 10px" }}>
        <div className="mr-2" style={{flex:"50%", width: "100%" }}>
          <div style={{ fontSize: "12px", color: "#737373", fontStyle: "", fontWeight: "bold" }}>Description</div>
           <textarea name="description" onChange={handleChange} style={{width:"100%", border:"1px solid #C9C9C9", outline: "none", padding: "0px 5px", color:"#737373"}}></textarea>
        </div>
        <div style={{flex:"50%", display:"flex",alignItems:"center", marginRight:"10px"}}></div>   
      </div>
      
      <Form className="pt-3" style={{backgroundColor:"white"}} >
        <div style={{padding:"5px 0px", width:"100px", display:"flex", justifyContent:"center", textAlign:"center", backgroundColor:"#94AAFF", borderRadius:"0px 5px 0px 0px" }}>
          Item 
        </div>
        <Col xl='12' sm='12'> 
          <div>
          <div style={{display:"block", height:"100%", overflowY:"auto",overflowX:"auto"}}>
            <div >
              <table className="table ">
                <thead>
                  <tr style={{ backgroundColor: "#E9EFFF" }}>
                    <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>No</th>    
                    <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Name</th>
                    <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Description</th>
                    <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Qty</th>
                    <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Price</th>
                    <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Tax</th>
                    <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none", fontFamily: "sans-serif" }}>Line Total</th>
                    <th style={{fontFamily:"revert",fontSize:"12px",textAlign:"center",  color:"#525252",border:"none", fontFamily:"sans-serif"}}>Action</th>
                  </tr>
                </thead>
                <tbody>
                {getDataItem.map((user,index) => (           
                  <tr style={{fontFamily:"sans-serif", fontSize:"11px", textAlign:"center"}}>
                    <td style={{ lineHeight: "3" }}>{index+1}</td>
                    <td style={{ lineHeight: "3" }}>
                      <div className="" style={{ display: "flex", justifyContent:"center" }}>
                          <div style={{ display: "flex", alignItems: "center", width:"100%"}}>
                            <div style={{ width: "100%" }}>
                              <select className="form-select" aria-label="Default select example" value={form?.id_medical_item} onChange={handleChange} name="id_medical_item" style={{textAlign:"", cursor:"pointer", fontSize: "12px", height:"6vh"}}>
                                <option value="" hidden>Select Item</option>
                                {getDataItem.map((user, index) => (
                                  <option value={user?.id} style={{textAlign: ""}}>{user?.name}</option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td style={{ lineHeight: "3" }}>
                      <input className="focused" name="qty" min={1} value={form?.qty} onChange={handleChange} style={{width:"100%", border:"1px solid #DCDCDC", textAlign:"center", borderRadius:"5px", height:"6vh"}}/>
                    </td>
                    <td style={{ lineHeight: "3" }}>
                      <input className="focused" name="description"  value={form?.description} onChange={handleChange} style={{width:"100%", border:"1px solid #DCDCDC", textAlign:"center", borderRadius:"5px", height:"6vh"}}/>
                    </td>
                    <td style={{ lineHeight: "3" }}>
                      <input className="focused" name="description"  value={form?.description} onChange={handleChange} style={{width:"100%", border:"1px solid #DCDCDC", textAlign:"center", borderRadius:"5px", height:"6vh"}}/>
                    </td>

                     <td style={{ lineHeight: "3" }}>
                      <div className="" style={{ display: "flex", justifyContent:"center" }}>
                          <div style={{ display: "flex", alignItems: "center", width:"100%"}}>
                            <div style={{ width: "100%" }}>
                              <select className="form-select" aria-label="Default select example" value={form?.id_medical_item} onChange={handleChange} name="id_medical_item" style={{textAlign:"", cursor:"pointer", fontSize: "12px", height:"6vh"}}>
                                <option value="" hidden>Select Tax</option>
                                {getDataItem.map((user, index) => (
                                  <option value={user?.id} style={{textAlign: ""}}>{user?.name}</option>
                                ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </td>  
                    <td style={{ lineHeight: "3" }}>
                      <input className="focused" name="description"  value={form?.description} onChange={handleChange} style={{width:"100%", border:"1px solid #DCDCDC", textAlign:"center", borderRadius:"5px", height:"6vh"}}/>
                    </td>
                    <td style={{lineHeight:"3"}}>
                        <div style={{display:"flex",justifyContent:"center", alignItems:"center", alignContent:"center"}}>
                          <div onClick={() => {deleteById(user?.id)}} style={{ fontSize: "12px", color: "white", backgroundColor: "#B60000", padding: "5px 5px", borderRadius: "3px", cursor: "pointer", border: "none", display:"flex" }}>
                            <FaTrash/>
                          </div>
                        </div>
                      </td> 
                  </tr>
                  ))}      
                </tbody>
              </table>
              
              </div>
            </div>
          </div>
        </Col>
      </Form>

      <div style={{ display: "flex", backgroundColor:"white", padding:"10px" }}>
        <div>
          <div onClick={handleAddClick} className="button-treatment-plan-add" style={{border:"none", backgroundColor:"#00E517", color:"white", borderRadius:"3px", fontSize:"12px", padding:"5px 10px", cursor:"pointer"}}>
            <FaPlus style={{marginRight:"5px", fontSize:"10px"}}/>Add
          </div>
        </div>
      </div>
    </div>
  );
}
